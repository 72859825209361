import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  EmailAddress: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Json: { input: any; output: any; }
  URL: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type ActivityLogEntry = {
  __typename?: 'ActivityLogEntry';
  change?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  type: ActivityLogEntryType;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type ActivityLogEntryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ActivityLogEntryOrderByWithRelationInput = {
  Voucher?: InputMaybe<VoucherOrderByWithRelationInput>;
  change?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  leadId?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  subject?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  voucherId?: InputMaybe<SortOrder>;
};

export enum ActivityLogEntryType {
  Created = 'CREATED',
  Memo = 'MEMO',
  StatusChange = 'STATUS_CHANGE',
  Sync = 'SYNC',
  Update = 'UPDATE'
}

export type ActivityLogEntryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type AddGiftcardToOrderGiftcardInput = {
  /** The amount to reserve in cents (EUR) */
  amount: Scalars['Int']['input'];
  giftcardNumber: Scalars['String']['input'];
  securityCode: Scalars['String']['input'];
};

export type AddPrivilegeToCartInput = {
  imageSource: Scalars['String']['input'];
  privilegeId: Scalars['String']['input'];
  reference: Scalars['String']['input'];
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  firstName: Scalars['String']['output'];
  gender: Gender;
  houseNumber: Scalars['String']['output'];
  houseNumberSuffix?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isBillingAddress: Scalars['Boolean']['output'];
  isShippingAddress: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type AddressInput = {
  city: Scalars['String']['input'];
  companyName?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: Gender;
  houseNumber: Scalars['String']['input'];
  houseNumberSuffix?: InputMaybe<Scalars['String']['input']>;
  isBillingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  isShippingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
};

export type AddressOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AdminAccountActivationInput = {
  activationKey: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AdminsPayload = {
  __typename?: 'AdminsPayload';
  items?: Maybe<Array<Maybe<User>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AdyenCreateCheckoutSessionInput = {
  orderId: Scalars['Int']['input'];
  returnUrl: Scalars['URL']['input'];
};

export type AdyenCreateCheckoutSessionPaymentPayload = {
  __typename?: 'AdyenCreateCheckoutSessionPaymentPayload';
  sessionData?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['String']['output']>;
};

export type AppliedGiftcards = {
  __typename?: 'AppliedGiftcards';
  appliedAmount?: Maybe<Scalars['Int']['output']>;
  giftcardNumber?: Maybe<Scalars['String']['output']>;
  securityCode?: Maybe<Scalars['String']['output']>;
};

export type Article = {
  __typename?: 'Article';
  articleAttributes?: Maybe<Array<Maybe<ArticleAttribute>>>;
  articleType?: Maybe<Scalars['String']['output']>;
  articleTypeID?: Maybe<Scalars['Int']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  collection?: Maybe<Scalars['String']['output']>;
  collectionNumber?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  eancode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ignoreStockCheck?: Maybe<Scalars['Boolean']['output']>;
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  inStock?: Maybe<Scalars['Boolean']['output']>;
  isOutOfCollection?: Maybe<Scalars['Boolean']['output']>;
  isPriceVisible?: Maybe<Scalars['Boolean']['output']>;
  isWebShopOnly?: Maybe<Scalars['Boolean']['output']>;
  isWebshopSale?: Maybe<Scalars['Boolean']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  salePrice?: Maybe<Scalars['Int']['output']>;
  subCategory?: Maybe<Scalars['String']['output']>;
  supplierNr?: Maybe<Scalars['String']['output']>;
  urlSlug?: Maybe<Scalars['String']['output']>;
  webID?: Maybe<Scalars['String']['output']>;
  webShopStatus?: Maybe<Scalars['String']['output']>;
};

export type ArticleAttribute = {
  __typename?: 'ArticleAttribute';
  attributeID?: Maybe<Scalars['Int']['output']>;
  attributeName?: Maybe<Scalars['String']['output']>;
  attributeOriginalName?: Maybe<Scalars['String']['output']>;
  attributeValue?: Maybe<Scalars['String']['output']>;
  attributeValueID?: Maybe<Scalars['Int']['output']>;
};

export type ArticleAttributeFilter = {
  __typename?: 'ArticleAttributeFilter';
  articleFilterAttributeValues?: Maybe<Array<Maybe<ArticleFilterAttributeValue>>>;
  attributeID?: Maybe<Scalars['Int']['output']>;
  attributeName?: Maybe<Scalars['String']['output']>;
};

export type ArticleFilterAttributeValue = {
  __typename?: 'ArticleFilterAttributeValue';
  attributeValue?: Maybe<Scalars['String']['output']>;
  attributeValueID?: Maybe<Scalars['Int']['output']>;
};

export enum ArticleSortEnum {
  New = 'new',
  Populair = 'populair',
  PriceAsc = 'priceASC',
  PriceDesc = 'priceDESC'
}

export type ArticleTypeFilter = {
  __typename?: 'ArticleTypeFilter';
  articleType?: Maybe<Scalars['String']['output']>;
  articleTypeID?: Maybe<Scalars['Int']['output']>;
};

export type ArticlesFilterInput = {
  articleTypeID?: InputMaybe<Scalars['Int']['input']>;
  attributeFilters?: InputMaybe<Array<InputMaybe<AttributeFilterFiltersInput>>>;
  brands?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  collections?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  excludeCollections?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  onlyMemberDiscountProducts?: InputMaybe<Scalars['Boolean']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  webID?: InputMaybe<Scalars['String']['input']>;
};

export type ArticlesPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ArticleSortEnum>;
};

export type ArticlesPayload = {
  __typename?: 'ArticlesPayload';
  items?: Maybe<Array<Maybe<Article>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AttributeFilterFiltersInput = {
  attributeID: Scalars['Int']['input'];
  attributeValueID: Array<Scalars['Int']['input']>;
};

export type BrandCollectionFilter = {
  __typename?: 'BrandCollectionFilter';
  collection?: Maybe<Scalars['String']['output']>;
  collectionNumber?: Maybe<Scalars['Int']['output']>;
};

export type BrandTypeFilter = {
  __typename?: 'BrandTypeFilter';
  brand?: Maybe<Scalars['String']['output']>;
  merk_nr?: Maybe<Scalars['Int']['output']>;
};

export type Cart = {
  __typename?: 'Cart';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  items?: Maybe<Array<Maybe<CartItem>>>;
  privilegeItems?: Maybe<Array<Maybe<PrivilegeCartItem>>>;
  shippingCosts: Scalars['Int']['output'];
  store: Store;
  totalItems?: Maybe<Scalars['Int']['output']>;
  totalPriceExTax?: Maybe<Scalars['Int']['output']>;
  totalPriceIncTax?: Maybe<Scalars['Int']['output']>;
  totalTax?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  vouchers: Array<CartVoucher>;
};


export type CartVouchersArgs = {
  after?: InputMaybe<CartVoucherWhereUniqueInput>;
  before?: InputMaybe<CartVoucherWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CartItem = {
  __typename?: 'CartItem';
  article?: Maybe<Article>;
  articleId: Scalars['String']['output'];
  choicesArticle?: Maybe<Scalars['Json']['output']>;
  choicesArticleCombination?: Maybe<Scalars['Json']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  size?: Maybe<Scalars['String']['output']>;
  tax: Scalars['Float']['output'];
  totalPrice?: Maybe<Scalars['Int']['output']>;
  unitPrice: Scalars['Int']['output'];
};

export type CartItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CartOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  items?: InputMaybe<CartItemOrderByRelationAggregateInput>;
  privilegeItems?: InputMaybe<PrivilegeCartItemOrderByRelationAggregateInput>;
  shippingCosts?: InputMaybe<SortOrder>;
  store?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  vouchers?: InputMaybe<CartVoucherOrderByRelationAggregateInput>;
};

export type CartVoucher = {
  __typename?: 'CartVoucher';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  type: VoucherType;
  value: Scalars['Int']['output'];
};

export type CartVoucherOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CartVoucherWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CdcCompleteSocialLoginInput = {
  UID: Scalars['String']['input'];
  UIDSignature: Scalars['String']['input'];
  cartReference?: InputMaybe<Scalars['String']['input']>;
  signatureTimestamp: Scalars['String']['input'];
};

export type CdcLoginInput = {
  cartReference?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailAddress']['input'];
  password: Scalars['String']['input'];
};

export type CdcRequestResendVerificationCodeInput = {
  email: Scalars['EmailAddress']['input'];
};

export enum ChangeOrderAddressType {
  Billing = 'billing',
  Shipping = 'shipping'
}

export type CheckGiftcardBalanceInput = {
  giftcardNumber: Scalars['String']['input'];
  securityCode: Scalars['String']['input'];
};

export type CheckoutContactInput = {
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CheckoutCustomerInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CheckoutInput = {
  billingAddress: AddressInput;
  contact: CheckoutContactInput;
  shippingAddress: AddressInput;
};

export type CheckoutMeta = {
  __typename?: 'CheckoutMeta';
  display_price?: Maybe<CheckoutMetaDisplayPrice>;
};

export type CheckoutMetaDisplayPrice = {
  __typename?: 'CheckoutMetaDisplayPrice';
  tax?: Maybe<CheckoutMetaPrice>;
  with_tax?: Maybe<CheckoutMetaPrice>;
  without_tax?: Maybe<CheckoutMetaPrice>;
};

export type CheckoutMetaInput = {
  deliveryType?: InputMaybe<OrderDeliveryType>;
  giftWrap?: InputMaybe<Scalars['Boolean']['input']>;
  giftWrapText?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  newsletterSignup?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CheckoutMetaPrice = {
  __typename?: 'CheckoutMetaPrice';
  amount?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  formatted?: Maybe<Scalars['String']['output']>;
};

export type CheckoutPayload = {
  __typename?: 'CheckoutPayload';
  id?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<CheckoutMeta>;
  payment?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ChoicesArticle = {
  __typename?: 'ChoicesArticle';
  component?: Maybe<Scalars['String']['output']>;
  cut?: Maybe<Scalars['String']['output']>;
  eancode?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  material?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  properties?: Maybe<Scalars['JSON']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  style?: Maybe<Scalars['String']['output']>;
  substyle?: Maybe<Scalars['String']['output']>;
};

export type ChoicesArticleCombination = {
  __typename?: 'ChoicesArticleCombination';
  articles: Array<ChoicesArticleCombinationArticle>;
  baseMaterial?: Maybe<Scalars['String']['output']>;
  collection: Scalars['String']['output'];
  combi_no: Scalars['Int']['output'];
  eancode?: Maybe<Scalars['String']['output']>;
  gemstone: Scalars['String']['output'];
  hasFixedComponent: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  images: Array<ChoicesArticleCombinationImage>;
  material?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  product_group: Scalars['String']['output'];
  seq_nr?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  style: Scalars['String']['output'];
  substyle?: Maybe<Scalars['String']['output']>;
};

export type ChoicesArticleCombinationArticle = {
  __typename?: 'ChoicesArticleCombinationArticle';
  component: Scalars['String']['output'];
  id: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type ChoicesArticleCombinationImage = {
  __typename?: 'ChoicesArticleCombinationImage';
  path: Scalars['String']['output'];
  position: Scalars['Int']['output'];
};

export type ChoicesArticleCombinationsFilter = {
  chainCollection?: InputMaybe<Scalars['Boolean']['input']>;
  material?: InputMaybe<ChoicesMaterialFilter>;
  productGroup?: InputMaybe<ChoicesProductGroupFilter>;
  size?: InputMaybe<ChoicesSizeFilter>;
  style?: InputMaybe<ChoicesStyleFilter>;
  themeProfile?: InputMaybe<ChoicesThemeProfileCodeFilter>;
};

export type ChoicesArticleCombinationsPagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['String']['input']>;
};

export type ChoicesArticleCombinationsPayload = {
  __typename?: 'ChoicesArticleCombinationsPayload';
  dynamicFilters: Scalars['JSON']['output'];
  fixedFilters: Scalars['JSON']['output'];
  items: Array<ChoicesArticleCombination>;
  total: Scalars['Int']['output'];
};

export type ChoicesArticlesFilter = {
  component?: InputMaybe<ChoicesComponentFilter>;
  cut?: InputMaybe<ChoicesCutFilter>;
  material?: InputMaybe<ChoicesMaterialFilter>;
  productGroup?: InputMaybe<ChoicesProductGroupFilter>;
  size?: InputMaybe<ChoicesSizeFilter>;
  style: ChoicesStyleFilter;
  substyle?: InputMaybe<ChoicesSubStyleFilter>;
};

export type ChoicesArticlesPagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['String']['input']>;
};

export type ChoicesArticlesPayload = {
  __typename?: 'ChoicesArticlesPayload';
  dynamicFilters: Scalars['JSON']['output'];
  fixedFilters: Scalars['JSON']['output'];
  items: Array<ChoicesArticle>;
  total: Scalars['Int']['output'];
};

export enum ChoicesComponentFilter {
  Base = 'base',
  Fixed = 'fixed',
  Frame = 'frame',
  Stone = 'stone'
}

export enum ChoicesCutFilter {
  Cabochon = 'cabochon',
  Faceted = 'faceted'
}

export enum ChoicesMaterialFilter {
  Ceramic = 'ceramic',
  Diamonds = 'diamonds',
  Rosegold = 'rosegold',
  Whitegold = 'whitegold',
  Yellowgold = 'yellowgold'
}

export enum ChoicesProductGroupFilter {
  Bracelet = 'bracelet',
  Earring = 'earring',
  Necklace = 'necklace',
  Ring = 'ring'
}

export type ChoicesRelatedCombination = {
  __typename?: 'ChoicesRelatedCombination';
  combinations: Array<ChoicesArticleCombination>;
  id: Scalars['String']['output'];
  productGroup: Scalars['String']['output'];
  size: Scalars['String']['output'];
  style: Scalars['String']['output'];
};

export type ChoicesRelatedCombinationFilters = {
  __typename?: 'ChoicesRelatedCombinationFilters';
  productGroup: Array<ChoicesRelatedCombinationFiltersItem>;
  size: Array<ChoicesRelatedCombinationFiltersItem>;
  style: Array<ChoicesRelatedCombinationFiltersItem>;
};

export type ChoicesRelatedCombinationFiltersItem = {
  __typename?: 'ChoicesRelatedCombinationFiltersItem';
  name: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

export type ChoicesRelatedCombinationsFilter = {
  productGroup?: InputMaybe<ChoicesProductGroupFilter>;
  size?: InputMaybe<ChoicesSizeFilter>;
  style?: InputMaybe<ChoicesStyleFilter>;
};

export type ChoicesRelatedCombinationsPagination = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type ChoicesRelatedCombinationsPayload = {
  __typename?: 'ChoicesRelatedCombinationsPayload';
  filters: ChoicesRelatedCombinationFilters;
  items: Array<ChoicesRelatedCombination>;
  total: Scalars['Int']['output'];
};

export enum ChoicesSizeFilter {
  Large = 'large',
  Medium = 'medium',
  Small = 'small'
}

export enum ChoicesStyleFilter {
  Classic = 'classic',
  Hippiechic = 'hippiechic',
  Onepiece = 'onepiece',
  Oval = 'oval',
  Twin = 'twin'
}

export enum ChoicesSubStyleFilter {
  EarringBase_1212 = 'earring_base_1212',
  EarringBaseAjour = 'earring_base_ajour',
  EarringBaseAnimal = 'earring_base_animal',
  EarringBaseBohemian = 'earring_base_bohemian',
  EarringBaseCapsule = 'earring_base_capsule',
  EarringBaseDiamonds_0709 = 'earring_base_diamonds_0709',
  EarringBaseEveningPlainDiamonds = 'earring_base_evening_plain_diamonds',
  EarringBaseGalaxy = 'earring_base_galaxy',
  EarringBaseGeo = 'earring_base_geo',
  EarringBaseHippieFlower = 'earring_base_hippie_flower',
  EarringBaseHippieLeaf = 'earring_base_hippie_leaf',
  EarringBaseHippiePearsh = 'earring_base_hippie_pearsh',
  EarringBaseHippiePearshape = 'earring_base_hippie_pearshape',
  EarringBaseHippiePython = 'earring_base_hippie_python',
  EarringBaseHippieStar = 'earring_base_hippie_star',
  EarringBaseLink = 'earring_base_link',
  EarringBaseMini_0808 = 'earring_base_mini_0808',
  EarringBasePlainDiamonds = 'earring_base_plain_diamonds',
  EarringBaseRaindrop = 'earring_base_raindrop',
  EarringBaseSimplicity = 'earring_base_simplicity',
  EarringBaseSimplicityDiamonds = 'earring_base_simplicity_diamonds',
  RingBase_0808 = 'ring_base_0808',
  RingBase_1212 = 'ring_base_1212',
  RingBase_1318 = 'ring_base_1318',
  RingBase_1622 = 'ring_base_1622',
  RingBase_1818 = 'ring_base_1818',
  RingBaseAjour_1212 = 'ring_base_ajour_1212',
  RingBaseAjour_1318 = 'ring_base_ajour_1318',
  RingBaseAjour_1622 = 'ring_base_ajour_1622',
  RingBaseAjour_1818 = 'ring_base_ajour_1818',
  RingBaseCeramic_1818 = 'ring_base_ceramic_1818',
  RingBaseCore_1212 = 'ring_base_core_1212',
  RingBaseCore_1622 = 'ring_base_core_1622',
  RingBaseCurlLong_0709 = 'ring_base_curl_long_0709',
  RingBaseDiamonds_0709 = 'ring_base_diamonds_0709',
  RingBaseDiamonds_1818 = 'ring_base_diamonds_1818',
  RingBaseStiletto_0709 = 'ring_base_stiletto_0709',
  RingBaseStiletto_0808 = 'ring_base_stiletto_0808',
  RingBaseTwist_0808 = 'ring_base_twist_0808'
}

export enum ChoicesThemeProfileCodeFilter {
  Chaincollection = 'CHAINCOLLECTION',
  Newcollection = 'NEWCOLLECTION'
}

export type ContactFormInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  contactPreference?: InputMaybe<ContactPreference>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: Gender;
  lastName: Scalars['String']['input'];
  leadStore?: InputMaybe<LeadStore>;
  location?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferredDate?: InputMaybe<Scalars['String']['input']>;
  preferredStore?: InputMaybe<Scalars['String']['input']>;
  productName?: InputMaybe<Scalars['String']['input']>;
  /** The location (mostly the URL) from which this is called. */
  referrer: Scalars['String']['input'];
  /** The title of the page the form is send from */
  referrerTitle: Scalars['String']['input'];
  store?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  type: LeadType;
  variant?: InputMaybe<ContactFormVariant>;
};

export enum ContactFormVariant {
  Gassan = 'GASSAN',
  Rolex = 'ROLEX'
}

export enum ContactPreference {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type CreateAccountInput = {
  address: AddressInput;
  /** Datestring formatted as YYYY-MM-DD */
  birthdate?: InputMaybe<Scalars['String']['input']>;
  choicesNewsletterOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gassanNewsletterOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  gender: Gender;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  /** Datestring formatted as YYYY-MM-DD */
  weddingdate?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAdminInput = {
  access: Array<UserAccess>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type CreateFormInput = {
  adminEmailRecipient?: InputMaybe<Scalars['String']['input']>;
  adminEmailSubject?: InputMaybe<Scalars['String']['input']>;
  autoReplyTemplates?: InputMaybe<Scalars['JSON']['input']>;
  customNewsletterText?: InputMaybe<Scalars['JSON']['input']>;
  customNumberOfPersons?: InputMaybe<Scalars['Int']['input']>;
  fields: Array<FormField>;
  limitToOneSubmission?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  redirectAfterSubmission?: InputMaybe<RedirectUrlsInput>;
  selectableDates?: InputMaybe<Array<Scalars['String']['input']>>;
  selectableTimes?: InputMaybe<Array<Scalars['String']['input']>>;
  store: FormStore;
};

export type CreateFormSubmissionInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  values: Scalars['JSON']['input'];
};

export type CreateVoucherInput = {
  code: Scalars['String']['input'];
  description: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  limitToBrands?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status: VoucherStatus;
  store: Store;
  type: VoucherType;
  validTill?: InputMaybe<Scalars['Date']['input']>;
  value: Scalars['Int']['input'];
};

export type DafLoyaltyStatus = {
  __typename?: 'DAFLoyaltyStatus';
  loyalty_description?: Maybe<Scalars['String']['output']>;
  loyalty_nr?: Maybe<Scalars['Int']['output']>;
  loyalty_progress?: Maybe<Scalars['Float']['output']>;
};

export enum DafOptins {
  Optin100 = 'optin100',
  Optin101 = 'optin101',
  Optin102 = 'optin102',
  Optin103 = 'optin103'
}

export type DafPreferenceItem = {
  __typename?: 'DAFPreferenceItem';
  description?: Maybe<Scalars['String']['output']>;
  isSelected?: Maybe<Scalars['Boolean']['output']>;
  preference_no?: Maybe<Scalars['Int']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
};

export type DafPreferenceTypeItem = {
  __typename?: 'DAFPreferenceTypeItem';
  preference_type_description?: Maybe<Scalars['String']['output']>;
  preference_type_id?: Maybe<Scalars['Int']['output']>;
  preferences?: Maybe<Array<Maybe<DafPreferenceItem>>>;
};

export enum DafPrivilegeType {
  Events = 'EVENTS',
  Message = 'MESSAGE',
  PartnerUrl = 'PartnerURL',
  Reservation = 'RESERVATION',
  Sales = 'SALES',
  Salesorder = 'SALESORDER'
}

export type DafPrivilegeTypeItem = {
  __typename?: 'DAFPrivilegeTypeItem';
  artikel_nr?: Maybe<Scalars['Int']['output']>;
  beschikbaar?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  max_aantal?: Maybe<Scalars['Int']['output']>;
  preference_type_description?: Maybe<DafPrivilegeType>;
  privilege_action?: Maybe<Scalars['String']['output']>;
  privilege_nr?: Maybe<Scalars['Int']['output']>;
};

export type DafService = {
  __typename?: 'DAFService';
  date: Scalars['String']['output'];
  maxprice: Scalars['Int']['output'];
  merk: Scalars['String']['output'];
  model_nr: Scalars['String']['output'];
  serial_nr: Scalars['String']['output'];
  service_nr: Scalars['Int']['output'];
  ttservice_tracking: Array<DafServiceStep>;
};

export type DafServiceStep = {
  __typename?: 'DAFServiceStep';
  date: Scalars['String']['output'];
  service_nr: Scalars['Int']['output'];
  status_name: Scalars['String']['output'];
  status_nr: Scalars['Int']['output'];
  tijd: Scalars['String']['output'];
};

export type DafUser = {
  __typename?: 'DAFUser';
  Klantnaam?: Maybe<Scalars['String']['output']>;
  geboortedatum?: Maybe<Scalars['String']['output']>;
  /** m | v */
  geslacht?: Maybe<Scalars['String']['output']>;
  huisnummer?: Maybe<Scalars['Int']['output']>;
  huisnummertoevoeging?: Maybe<Scalars['String']['output']>;
  isokode?: Maybe<Scalars['String']['output']>;
  klant_id?: Maybe<Scalars['String']['output']>;
  klant_nr?: Maybe<Scalars['Int']['output']>;
  loyalty_progress?: Maybe<Scalars['Float']['output']>;
  mobiel?: Maybe<Scalars['String']['output']>;
  my_loyalty?: Maybe<Scalars['String']['output']>;
  optins?: Maybe<Array<Maybe<DafUserOptin>>>;
  pgeboortedatum?: Maybe<Scalars['String']['output']>;
  pgeslacht?: Maybe<Scalars['String']['output']>;
  plaatsnaam?: Maybe<Scalars['String']['output']>;
  pnaam?: Maybe<Scalars['String']['output']>;
  polsmaat?: Maybe<Scalars['Int']['output']>;
  postkode?: Maybe<Scalars['String']['output']>;
  pvoorletters?: Maybe<Scalars['String']['output']>;
  pvoornaam?: Maybe<Scalars['String']['output']>;
  ringmaat_links?: Maybe<Scalars['Int']['output']>;
  ringmaat_rechts?: Maybe<Scalars['Int']['output']>;
  straatnaam?: Maybe<Scalars['String']['output']>;
  telefoon?: Maybe<Scalars['String']['output']>;
  trouwdatum?: Maybe<Scalars['String']['output']>;
  voorletters?: Maybe<Scalars['String']['output']>;
  voornaam?: Maybe<Scalars['String']['output']>;
};

export type DafUserOptin = {
  __typename?: 'DAFUserOptin';
  description?: Maybe<Scalars['String']['output']>;
  optin?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type EnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type EnumStoreFilter = {
  equals?: InputMaybe<Store>;
  in?: InputMaybe<Array<Store>>;
  not?: InputMaybe<NestedEnumStoreFilter>;
  notIn?: InputMaybe<Array<Store>>;
};

export type EnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  not?: InputMaybe<NestedEnumUserStatusFilter>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type EnumVoucherStatusFilter = {
  equals?: InputMaybe<VoucherStatus>;
  in?: InputMaybe<Array<VoucherStatus>>;
  not?: InputMaybe<NestedEnumVoucherStatusFilter>;
  notIn?: InputMaybe<Array<VoucherStatus>>;
};

export type EnumVoucherTypeFilter = {
  equals?: InputMaybe<VoucherType>;
  in?: InputMaybe<Array<VoucherType>>;
  not?: InputMaybe<NestedEnumVoucherTypeFilter>;
  notIn?: InputMaybe<Array<VoucherType>>;
};

export type EventFormInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  dietaryRequirements: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  heardOfGassanVia: Scalars['String']['input'];
  houseNumber: Scalars['String']['input'];
  houseNumberSuffix: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  note: Scalars['String']['input'];
  numberOfPersons: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  plazaBrilliantNews?: InputMaybe<Scalars['Boolean']['input']>;
  postalCode: Scalars['String']['input'];
  preferredDate: Scalars['String']['input'];
  preferredTime: Scalars['String']['input'];
  /** The location (mostly the URL) from which this is called. */
  referrer: Scalars['String']['input'];
  street: Scalars['String']['input'];
  title: Scalars['String']['input'];
  toEmail: Scalars['Int']['input'];
  urlSlug?: InputMaybe<Scalars['String']['input']>;
};

export type ExportOrdersInput = {
  from?: InputMaybe<Scalars['String']['input']>;
  till?: InputMaybe<Scalars['String']['input']>;
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  name: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  type: FileType;
  updatedAt: Scalars['DateTime']['output'];
  uploadedBy?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type FileOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum FileType {
  Invoice = 'INVOICE',
  Other = 'OTHER',
  Receipt = 'RECEIPT',
  WarrantyCard = 'WARRANTY_CARD'
}

export type FileWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FilesPayload = {
  __typename?: 'FilesPayload';
  items?: Maybe<Array<Maybe<File>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type FilterPayload = {
  __typename?: 'FilterPayload';
  articleAttributeFilters?: Maybe<Array<Maybe<ArticleAttributeFilter>>>;
  articleTypeFilters?: Maybe<Array<Maybe<ArticleTypeFilter>>>;
  brandCollectionFilters?: Maybe<Array<Maybe<BrandCollectionFilter>>>;
  brandFilters?: Maybe<Array<Maybe<BrandTypeFilter>>>;
};

export type Form = {
  __typename?: 'Form';
  adminEmailRecipient?: Maybe<Scalars['String']['output']>;
  adminEmailSubject?: Maybe<Scalars['String']['output']>;
  autoReplyTemplates?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customNewsletterText?: Maybe<Scalars['JSON']['output']>;
  customNumberOfPersons?: Maybe<Scalars['Int']['output']>;
  fields: Array<FormField>;
  id: Scalars['ID']['output'];
  lastSubmissionDate?: Maybe<Scalars['DateTime']['output']>;
  limitToOneSubmission: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  redirectAfterSubmission?: Maybe<Scalars['JSON']['output']>;
  selectableDates: Array<Scalars['String']['output']>;
  selectableTimes: Array<Scalars['String']['output']>;
  store: FormStore;
  submissionsCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum FormField {
  CreateAccount = 'CREATE_ACCOUNT',
  Date = 'DATE',
  DietaryRequirements = 'DIETARY_REQUIREMENTS',
  GassanPlazaNews = 'GASSAN_PLAZA_NEWS',
  HeardOfGassanVia = 'HEARD_OF_GASSAN_VIA',
  HomeAddress = 'HOME_ADDRESS',
  Message = 'MESSAGE',
  NewsletterMandatory = 'NEWSLETTER_MANDATORY',
  NumberOfPersons = 'NUMBER_OF_PERSONS',
  PhoneNumber = 'PHONE_NUMBER',
  Time = 'TIME'
}

export type FormOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum FormStore {
  Gassan = 'GASSAN',
  Trophy = 'TROPHY'
}

export type FormSubmission = {
  __typename?: 'FormSubmission';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  preferredLanguage: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  values: Scalars['JSON']['output'];
};

export type FormSubmissionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FormSubmissionsFilterInput = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type FormSubmissionsOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
};

export type FormSubmissionsPayload = {
  __typename?: 'FormSubmissionsPayload';
  items: Array<FormSubmission>;
  total: Scalars['Int']['output'];
};

export type FormsFilterInput = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type FormsOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type FormsPayload = {
  __typename?: 'FormsPayload';
  items: Array<Form>;
  total: Scalars['Int']['output'];
};

export type ForwardLeadInput = {
  description: Scalars['String']['input'];
  email: Scalars['String']['input'];
  leadId: Scalars['String']['input'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export type Giftcard = {
  __typename?: 'Giftcard';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  originalValue?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type GiftcardInput = {
  code: Scalars['String']['input'];
  securityCode: Scalars['String']['input'];
};

export type GiftcardOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type Invoice = {
  __typename?: 'Invoice';
  adres?: Maybe<Scalars['String']['output']>;
  bonnr?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  datum?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  /** The id is build up like: {administratienummer}{locatie_nr}{bonnr} */
  id?: Maybe<Scalars['String']['output']>;
  /** The idRef is build up like: {administratienummer}-{locatie_nr}-{bonnr} */
  idRef?: Maybe<Scalars['String']['output']>;
  klant_naam?: Maybe<Scalars['String']['output']>;
  location?: Maybe<InvoiceLocation>;
  order?: Maybe<Order>;
  phone?: Maybe<Scalars['String']['output']>;
  postalcode?: Maybe<Scalars['String']['output']>;
  sales_lines?: Maybe<Array<Maybe<InvoiceSalesLine>>>;
  sales_payments?: Maybe<Array<Maybe<InvoiceSalesPayment>>>;
  shipping_cost?: Maybe<Scalars['Int']['output']>;
  tijd?: Maybe<Scalars['Int']['output']>;
  webshop_order_id?: Maybe<Scalars['Int']['output']>;
};

export type InvoiceLocation = {
  __typename?: 'InvoiceLocation';
  locatie_nr?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Scalars['String']['output']>;
};

export type InvoiceSalesLine = {
  __typename?: 'InvoiceSalesLine';
  article?: Maybe<Article>;
  artikel_bedrag_excl?: Maybe<Scalars['Float']['output']>;
  artikel_bedrag_incl?: Maybe<Scalars['Float']['output']>;
  artikel_karaat?: Maybe<Scalars['Float']['output']>;
  artikel_nr?: Maybe<Scalars['String']['output']>;
  artikel_prijs_excl?: Maybe<Scalars['Float']['output']>;
  artikel_prijs_incl?: Maybe<Scalars['Float']['output']>;
  artikel_stuks?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  taxfree?: Maybe<Scalars['Boolean']['output']>;
};

export type InvoiceSalesPayment = {
  __typename?: 'InvoiceSalesPayment';
  betaalwijze?: Maybe<Scalars['String']['output']>;
  betalingsbedrag?: Maybe<Scalars['Float']['output']>;
  betalingsbedrag_val?: Maybe<Scalars['Float']['output']>;
  valutakode?: Maybe<Scalars['String']['output']>;
};

export type InvoicesPayload = {
  __typename?: 'InvoicesPayload';
  items?: Maybe<Array<Maybe<Invoice>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Lead = {
  __typename?: 'Lead';
  activityLog: Array<ActivityLogEntry>;
  articleId?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  contactPreference?: Maybe<LeadContactPreference>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  fileIds: Array<File>;
  fileUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender: Gender;
  id: Scalars['String']['output'];
  language: LeadLanguage;
  lastName?: Maybe<Scalars['String']['output']>;
  leadStore: LeadStore;
  location?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  preOwnedAdditionalItems?: Maybe<LeadAdditionalItems>;
  preOwnedBrand?: Maybe<Scalars['String']['output']>;
  preOwnedCondition?: Maybe<LeadPreOwnedCondition>;
  preOwnedExpectedPrice?: Maybe<Scalars['String']['output']>;
  preOwnedModel?: Maybe<Scalars['String']['output']>;
  preOwnedReference?: Maybe<Scalars['String']['output']>;
  preOwnedTradeType?: Maybe<LeadTradeType>;
  preOwnedYear?: Maybe<Scalars['String']['output']>;
  preferredDate?: Maybe<Scalars['String']['output']>;
  preferredStore?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  referrer: Scalars['String']['output'];
  referrerTitle: Scalars['String']['output'];
  responses: Array<LeadResponse>;
  status: LeadStatus;
  store?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  subscribeToNewsletter?: Maybe<Scalars['Boolean']['output']>;
  type: LeadType;
  updatedAt: Scalars['DateTime']['output'];
};


export type LeadActivityLogArgs = {
  after?: InputMaybe<ActivityLogEntryWhereUniqueInput>;
  before?: InputMaybe<ActivityLogEntryWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LeadActivityLogOrderByInput>>;
};


export type LeadFileIdsArgs = {
  after?: InputMaybe<FileWhereUniqueInput>;
  before?: InputMaybe<FileWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadResponsesArgs = {
  after?: InputMaybe<LeadResponseWhereUniqueInput>;
  before?: InputMaybe<LeadResponseWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LeadResponsesOrderByInput>>;
};

export type LeadActivityLogOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
};

export enum LeadAdditionalItems {
  JustWatch = 'JUST_WATCH',
  OriginalBox = 'ORIGINAL_BOX',
  OriginalBoxPapers = 'ORIGINAL_BOX_PAPERS',
  OriginalPapers = 'ORIGINAL_PAPERS'
}

export enum LeadContactPreference {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export enum LeadLanguage {
  De = 'DE',
  En = 'EN',
  Nl = 'NL'
}

export type LeadOrderByWithRelationInput = {
  activityLog?: InputMaybe<ActivityLogEntryOrderByRelationAggregateInput>;
  articleId?: InputMaybe<SortOrder>;
  brand?: InputMaybe<SortOrder>;
  contactPreference?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fileIds?: InputMaybe<FileOrderByRelationAggregateInput>;
  firstName?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  leadStore?: InputMaybe<SortOrder>;
  location?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  preOwnedAdditionalItems?: InputMaybe<SortOrder>;
  preOwnedBrand?: InputMaybe<SortOrder>;
  preOwnedCondition?: InputMaybe<SortOrder>;
  preOwnedExpectedPrice?: InputMaybe<SortOrder>;
  preOwnedModel?: InputMaybe<SortOrder>;
  preOwnedReference?: InputMaybe<SortOrder>;
  preOwnedTradeType?: InputMaybe<SortOrder>;
  preOwnedYear?: InputMaybe<SortOrder>;
  preferredDate?: InputMaybe<SortOrder>;
  preferredStore?: InputMaybe<SortOrder>;
  productName?: InputMaybe<SortOrder>;
  referrer?: InputMaybe<SortOrder>;
  referrerTitle?: InputMaybe<SortOrder>;
  responses?: InputMaybe<LeadResponseOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  store?: InputMaybe<SortOrder>;
  subject?: InputMaybe<SortOrder>;
  subscribeToNewsletter?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum LeadPreOwnedCondition {
  Acceptable = 'ACCEPTABLE',
  Excellent = 'EXCELLENT',
  Good = 'GOOD',
  Unworn = 'UNWORN'
}

export type LeadResponse = {
  __typename?: 'LeadResponse';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  user: User;
};

export type LeadResponseOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type LeadResponseWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type LeadResponsesOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
};

export enum LeadStatus {
  Answered = 'ANSWERED',
  Forwarded = 'FORWARDED',
  InProgress = 'IN_PROGRESS',
  New = 'NEW'
}

export enum LeadStore {
  Gassan = 'GASSAN',
  PreOwned = 'PRE_OWNED'
}

export enum LeadTradeType {
  Sell = 'SELL',
  Trade = 'TRADE'
}

export enum LeadType {
  Appointment = 'APPOINTMENT',
  Contact = 'CONTACT',
  Question = 'QUESTION',
  SubmitPreOwnedWatch = 'SUBMIT_PRE_OWNED_WATCH'
}

export type LeadWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type LeadsFilterInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<LeadStatus>>>;
  store?: InputMaybe<Array<InputMaybe<LeadStore>>>;
  till?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Array<InputMaybe<LeadType>>>;
};

export type LeadsPayload = {
  __typename?: 'LeadsPayload';
  items?: Maybe<Array<Maybe<Lead>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  authToken?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export enum LoginTo {
  Account = 'account',
  Admin = 'admin'
}

export type Mutation = {
  __typename?: 'Mutation';
  addAddress?: Maybe<Address>;
  addArticleToCart?: Maybe<Cart>;
  addGiftcard?: Maybe<Giftcard>;
  addGiftcardToCart?: Maybe<Cart>;
  addLeadMemo?: Maybe<Lead>;
  addLeadResponse?: Maybe<Lead>;
  addOrderMemo?: Maybe<Order>;
  addPrivilegeToCart?: Maybe<Cart>;
  addVoucher?: Maybe<Cart>;
  adminAccountActivation?: Maybe<LoginPayload>;
  adyenCreateCheckoutSession: AdyenCreateCheckoutSessionPaymentPayload;
  cdcCompleteSocialLogin: Scalars['Boolean']['output'];
  cdcLogin: Scalars['Boolean']['output'];
  cdcRequestResendVerificationCode: Scalars['Boolean']['output'];
  changeLeadStatus?: Maybe<Lead>;
  changeOrderAddress?: Maybe<Order>;
  changeOrderStatus?: Maybe<Order>;
  /** This returns the full amount that is left on the giftcard */
  checkGiftCardBalance?: Maybe<Scalars['Int']['output']>;
  /** Converts a cart to an order */
  checkout?: Maybe<Order>;
  choicesAddArticleCombinationToCart?: Maybe<Cart>;
  choicesAddArticleToCart?: Maybe<Cart>;
  choicesAddGiftcardToCart?: Maybe<Cart>;
  choicesWarrantySignup?: Maybe<Scalars['Boolean']['output']>;
  clearCart?: Maybe<Cart>;
  createAccount?: Maybe<Scalars['Boolean']['output']>;
  createAdmin?: Maybe<User>;
  createForm: Form;
  createFormSubmission: FormSubmission;
  createFormSubmissionsExport: Scalars['Boolean']['output'];
  createVoucher?: Maybe<Voucher>;
  deleteAddress?: Maybe<Scalars['Boolean']['output']>;
  deleteAdmin?: Maybe<User>;
  deleteCart?: Maybe<Scalars['Boolean']['output']>;
  deleteForm: Scalars['Boolean']['output'];
  deleteFormSubmission: Scalars['Boolean']['output'];
  deleteGiftcard?: Maybe<Scalars['Boolean']['output']>;
  deleteUserFile?: Maybe<Scalars['String']['output']>;
  deleteVoucher?: Maybe<Voucher>;
  downloadInvoice?: Maybe<Scalars['String']['output']>;
  downloadUserFile?: Maybe<Scalars['String']['output']>;
  exportOrders?: Maybe<Scalars['String']['output']>;
  forwardLead?: Maybe<Lead>;
  login?: Maybe<LoginPayload>;
  newsletterSignup?: Maybe<Scalars['Boolean']['output']>;
  orderAddGiftcard: Scalars['Boolean']['output'];
  orderAddVoucher: Scalars['Boolean']['output'];
  orderRemoveGiftcard: Scalars['Boolean']['output'];
  orderRemoveVoucher: Scalars['Boolean']['output'];
  orderVerifyFullyPaid: OrderVerifyFullyPaidPayload;
  payment?: Maybe<Scalars['Json']['output']>;
  paymentDetails?: Maybe<Scalars['Json']['output']>;
  removeItemFromCart?: Maybe<Cart>;
  removePrivilegeItemFromCart?: Maybe<Cart>;
  removeVoucher?: Maybe<Cart>;
  renewToken?: Maybe<LoginPayload>;
  requestResetPassword?: Maybe<Scalars['Boolean']['output']>;
  resetPassword?: Maybe<LoginPayload>;
  returnOrderRequest?: Maybe<Scalars['Boolean']['output']>;
  submitContactForm: Scalars['Boolean']['output'];
  submitEventForm?: Maybe<Scalars['Boolean']['output']>;
  submitPreOwnedWatch?: Maybe<Scalars['Boolean']['output']>;
  syncDafManual?: Maybe<Order>;
  syncMyParcelManual?: Maybe<Order>;
  updateAddress?: Maybe<Address>;
  updateAdmin?: Maybe<User>;
  updateCartItemQuantity?: Maybe<Cart>;
  updateCartItemSize?: Maybe<Cart>;
  updateForm: Form;
  updateNewsletterOptin?: Maybe<Scalars['Boolean']['output']>;
  updateNewsletterPreferences?: Maybe<User>;
  updatePartnerInfo?: Maybe<User>;
  updatePassword?: Maybe<Scalars['Boolean']['output']>;
  updatePreferences?: Maybe<Array<Maybe<DafPreferenceTypeItem>>>;
  updateProfile?: Maybe<User>;
  updateShippingCosts?: Maybe<Cart>;
  updateUserWishlist?: Maybe<UserWishlist>;
  updateVoucher?: Maybe<Voucher>;
  uploadFileGetId?: Maybe<Scalars['String']['output']>;
  uploadUserFile?: Maybe<File>;
  /** This returns the full amount on the giftcard if the total cart amount is higher then the giftcard value, else it wil return the total giftcard value */
  validateGiftCard?: Maybe<Scalars['Int']['output']>;
  /** This returns an array of applied giftcards and an accumulated total amount of applied giftcards */
  validateGiftCards?: Maybe<ValidateGiftcardsPayload>;
  version: Scalars['String']['output'];
};


export type MutationAddAddressArgs = {
  input: AddressInput;
};


export type MutationAddArticleToCartArgs = {
  articleId: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  reference: Scalars['String']['input'];
  size?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddGiftcardArgs = {
  input: GiftcardInput;
};


export type MutationAddGiftcardToCartArgs = {
  amount: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  reference: Scalars['String']['input'];
};


export type MutationAddLeadMemoArgs = {
  leadId: Scalars['String']['input'];
  memo: Scalars['String']['input'];
};


export type MutationAddLeadResponseArgs = {
  id: Scalars['String']['input'];
  message: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};


export type MutationAddOrderMemoArgs = {
  memo: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
};


export type MutationAddPrivilegeToCartArgs = {
  input: AddPrivilegeToCartInput;
};


export type MutationAddVoucherArgs = {
  code: Scalars['String']['input'];
  reference: Scalars['String']['input'];
};


export type MutationAdminAccountActivationArgs = {
  input: AdminAccountActivationInput;
};


export type MutationAdyenCreateCheckoutSessionArgs = {
  input: AdyenCreateCheckoutSessionInput;
};


export type MutationCdcCompleteSocialLoginArgs = {
  input: CdcCompleteSocialLoginInput;
};


export type MutationCdcLoginArgs = {
  input: CdcLoginInput;
};


export type MutationCdcRequestResendVerificationCodeArgs = {
  input: CdcRequestResendVerificationCodeInput;
};


export type MutationChangeLeadStatusArgs = {
  leadId: Scalars['String']['input'];
  memo?: InputMaybe<Scalars['String']['input']>;
  status: LeadStatus;
};


export type MutationChangeOrderAddressArgs = {
  address: AddressInput;
  orderId: Scalars['Int']['input'];
  variant: ChangeOrderAddressType;
};


export type MutationChangeOrderStatusArgs = {
  memo?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['Int']['input'];
  status: OrderStatus;
};


export type MutationCheckGiftCardBalanceArgs = {
  input: CheckGiftcardBalanceInput;
};


export type MutationCheckoutArgs = {
  input?: InputMaybe<CheckoutInput>;
  meta?: InputMaybe<CheckoutMetaInput>;
  reference: Scalars['String']['input'];
};


export type MutationChoicesAddArticleCombinationToCartArgs = {
  articleId: Scalars['String']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  reference: Scalars['String']['input'];
  size?: InputMaybe<Scalars['String']['input']>;
};


export type MutationChoicesAddArticleToCartArgs = {
  articleId: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  reference: Scalars['String']['input'];
  size?: InputMaybe<Scalars['String']['input']>;
};


export type MutationChoicesAddGiftcardToCartArgs = {
  amount: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  reference: Scalars['String']['input'];
};


export type MutationChoicesWarrantySignupArgs = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  newsletterSignup: Scalars['Boolean']['input'];
  purchaseDate: Scalars['String']['input'];
};


export type MutationClearCartArgs = {
  reference: Scalars['String']['input'];
};


export type MutationCreateAccountArgs = {
  cartReference?: InputMaybe<Scalars['String']['input']>;
  input: CreateAccountInput;
  url?: InputMaybe<Scalars['String']['input']>;
  wishlist?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};


export type MutationCreateFormArgs = {
  input: CreateFormInput;
};


export type MutationCreateFormSubmissionArgs = {
  formId: Scalars['String']['input'];
  input: CreateFormSubmissionInput;
};


export type MutationCreateFormSubmissionsExportArgs = {
  formId: Scalars['String']['input'];
};


export type MutationCreateVoucherArgs = {
  input: CreateVoucherInput;
};


export type MutationDeleteAddressArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteAdminArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCartArgs = {
  reference: Scalars['String']['input'];
};


export type MutationDeleteFormArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteFormSubmissionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteGiftcardArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserFileArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteVoucherArgs = {
  id: Scalars['String']['input'];
};


export type MutationDownloadInvoiceArgs = {
  ref: Scalars['String']['input'];
};


export type MutationDownloadUserFileArgs = {
  id: Scalars['String']['input'];
};


export type MutationExportOrdersArgs = {
  input: ExportOrdersInput;
};


export type MutationForwardLeadArgs = {
  input: ForwardLeadInput;
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  loginTo: LoginTo;
  password: Scalars['String']['input'];
};


export type MutationNewsletterSignupArgs = {
  input?: InputMaybe<NewsletterSignupInput>;
};


export type MutationOrderAddGiftcardArgs = {
  id: Scalars['Int']['input'];
  input: AddGiftcardToOrderGiftcardInput;
};


export type MutationOrderAddVoucherArgs = {
  code: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};


export type MutationOrderRemoveGiftcardArgs = {
  giftcardNumber: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};


export type MutationOrderRemoveVoucherArgs = {
  code: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};


export type MutationOrderVerifyFullyPaidArgs = {
  id: Scalars['Int']['input'];
};


export type MutationPaymentArgs = {
  input: PaymentInput;
};


export type MutationPaymentDetailsArgs = {
  input: PaymentDetailsInput;
};


export type MutationRemoveItemFromCartArgs = {
  cartItemId: Scalars['String']['input'];
  reference: Scalars['String']['input'];
};


export type MutationRemovePrivilegeItemFromCartArgs = {
  input: RemovePrivilegeItemFromCartInput;
};


export type MutationRemoveVoucherArgs = {
  code: Scalars['String']['input'];
  reference: Scalars['String']['input'];
};


export type MutationRenewTokenArgs = {
  authToken: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};


export type MutationRequestResetPasswordArgs = {
  email: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationReturnOrderRequestArgs = {
  input: ReturnOrderRequestInput;
};


export type MutationSubmitContactFormArgs = {
  input: ContactFormInput;
  newsletterSignup?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSubmitEventFormArgs = {
  input: EventFormInput;
};


export type MutationSubmitPreOwnedWatchArgs = {
  input: PreOwnedWatchInput;
};


export type MutationSyncDafManualArgs = {
  id: Scalars['Int']['input'];
};


export type MutationSyncMyParcelManualArgs = {
  id: Scalars['Int']['input'];
};


export type MutationUpdateAddressArgs = {
  id: Scalars['String']['input'];
  input: AddressInput;
};


export type MutationUpdateAdminArgs = {
  id: Scalars['String']['input'];
  input: UpdateAdminInput;
};


export type MutationUpdateCartItemQuantityArgs = {
  cartItemId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  reference: Scalars['String']['input'];
};


export type MutationUpdateCartItemSizeArgs = {
  cartItemId: Scalars['String']['input'];
  reference: Scalars['String']['input'];
  size?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateFormArgs = {
  id: Scalars['String']['input'];
  input: UpdateFormInput;
};


export type MutationUpdateNewsletterOptinArgs = {
  input: UpdateNewsletterOptinInput;
};


export type MutationUpdateNewsletterPreferencesArgs = {
  optins: Array<DafOptins>;
};


export type MutationUpdatePartnerInfoArgs = {
  input: UpdatePartnerInfoInput;
};


export type MutationUpdatePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};


export type MutationUpdatePreferencesArgs = {
  input: Array<UpdatePreferencesInput>;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateShippingCostsArgs = {
  countryCode: Scalars['String']['input'];
  reference: Scalars['String']['input'];
};


export type MutationUpdateUserWishlistArgs = {
  articleIds: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  status: UserWishlistStatus;
};


export type MutationUpdateVoucherArgs = {
  id: Scalars['String']['input'];
  input: UpdateVoucherInput;
};


export type MutationUploadFileGetIdArgs = {
  upload: Scalars['Upload']['input'];
};


export type MutationUploadUserFileArgs = {
  input: UploadFileInput;
  upload: Scalars['Upload']['input'];
};


export type MutationValidateGiftCardArgs = {
  input: ValidateGiftCardInput;
  reference: Scalars['String']['input'];
};


export type MutationValidateGiftCardsArgs = {
  input: Array<ValidateGiftCardsInput>;
  reference: Scalars['String']['input'];
};

export type NestedEnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedEnumStoreFilter = {
  equals?: InputMaybe<Store>;
  in?: InputMaybe<Array<Store>>;
  not?: InputMaybe<NestedEnumStoreFilter>;
  notIn?: InputMaybe<Array<Store>>;
};

export type NestedEnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  not?: InputMaybe<NestedEnumUserStatusFilter>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type NestedEnumVoucherStatusFilter = {
  equals?: InputMaybe<VoucherStatus>;
  in?: InputMaybe<Array<VoucherStatus>>;
  not?: InputMaybe<NestedEnumVoucherStatusFilter>;
  notIn?: InputMaybe<Array<VoucherStatus>>;
};

export type NestedEnumVoucherTypeFilter = {
  equals?: InputMaybe<VoucherType>;
  in?: InputMaybe<Array<VoucherType>>;
  not?: InputMaybe<NestedEnumVoucherTypeFilter>;
  notIn?: InputMaybe<Array<VoucherType>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NewsletterSignupInput = {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  brands?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  choicesNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  freeWhitepaper?: InputMaybe<Scalars['Boolean']['input']>;
  gassanNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  /** Location where this user signed up */
  signedUpFrom?: InputMaybe<Scalars['String']['input']>;
};

export type Order = {
  __typename?: 'Order';
  activityLog: Array<ActivityLogEntry>;
  billingAddress: OrderAddress;
  contactEmail: Scalars['String']['output'];
  contactFullName: Scalars['String']['output'];
  contactPhone: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deliveryType: OrderDeliveryType;
  giftWrap: Scalars['Boolean']['output'];
  giftWrapText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  items?: Maybe<Array<Maybe<OrderItem>>>;
  key: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  privilegeItems?: Maybe<Array<Maybe<PrivilegeOrderItem>>>;
  shippingAddress: OrderAddress;
  shippingCosts: Scalars['Int']['output'];
  status: OrderStatus;
  store: Store;
  totalDiscount: Scalars['Int']['output'];
  totalPriceExTax: Scalars['Int']['output'];
  totalPriceIncTax: Scalars['Int']['output'];
  totalTax: Scalars['Int']['output'];
  transactions: Array<Transaction>;
  type: OrderType;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  vouchers: Array<OrderVoucher>;
};


export type OrderActivityLogArgs = {
  after?: InputMaybe<ActivityLogEntryWhereUniqueInput>;
  before?: InputMaybe<ActivityLogEntryWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrderActivityLogOrderByInput>>;
};


export type OrderTransactionsArgs = {
  after?: InputMaybe<TransactionWhereUniqueInput>;
  before?: InputMaybe<TransactionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderVouchersArgs = {
  after?: InputMaybe<OrderVoucherWhereUniqueInput>;
  before?: InputMaybe<OrderVoucherWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderActivityLogOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  city: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender: Gender;
  houseNumber: Scalars['String']['output'];
  houseNumberSuffix?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
};

export type OrderAddressOrderByWithRelationInput = {
  billingAddressOrder?: InputMaybe<OrderOrderByWithRelationInput>;
  city?: InputMaybe<SortOrder>;
  companyName?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  houseNumber?: InputMaybe<SortOrder>;
  houseNumberSuffix?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  shippingAddressOrder?: InputMaybe<OrderOrderByWithRelationInput>;
  state?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
};

export enum OrderDeliveryType {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP'
}

export type OrderItem = {
  __typename?: 'OrderItem';
  article?: Maybe<Article>;
  articleId: Scalars['String']['output'];
  choicesArticle?: Maybe<Scalars['Json']['output']>;
  choicesArticleCombination?: Maybe<Scalars['Json']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  size?: Maybe<Scalars['String']['output']>;
  tax: Scalars['Float']['output'];
  totalPrice?: Maybe<Scalars['Int']['output']>;
  unitPrice: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderOrderByWithRelationInput = {
  activityLog?: InputMaybe<ActivityLogEntryOrderByRelationAggregateInput>;
  billingAddress?: InputMaybe<OrderAddressOrderByWithRelationInput>;
  billingAddressId?: InputMaybe<SortOrder>;
  contactEmail?: InputMaybe<SortOrder>;
  contactFullName?: InputMaybe<SortOrder>;
  contactPhone?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deliveryType?: InputMaybe<SortOrder>;
  giftWrap?: InputMaybe<SortOrder>;
  giftWrapText?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  items?: InputMaybe<OrderItemOrderByRelationAggregateInput>;
  key?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  preferredLanguage?: InputMaybe<SortOrder>;
  privilegeItems?: InputMaybe<PrivilegeOrderItemOrderByRelationAggregateInput>;
  shippingAddress?: InputMaybe<OrderAddressOrderByWithRelationInput>;
  shippingAddressId?: InputMaybe<SortOrder>;
  shippingCosts?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  store?: InputMaybe<SortOrder>;
  totalDiscount?: InputMaybe<SortOrder>;
  totalPriceExTax?: InputMaybe<SortOrder>;
  totalPriceIncTax?: InputMaybe<SortOrder>;
  totalTax?: InputMaybe<SortOrder>;
  tourBooking?: InputMaybe<TourBookingOrderByWithRelationInput>;
  tourBookingId?: InputMaybe<SortOrder>;
  transactions?: InputMaybe<TransactionOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  vouchers?: InputMaybe<OrderVoucherOrderByRelationAggregateInput>;
};

export enum OrderStatus {
  Fullfilled = 'FULLFILLED',
  New = 'NEW',
  NoStock = 'NO_STOCK',
  Payed = 'PAYED',
  ReturnProcessed = 'RETURN_PROCESSED',
  ReturnRegistered = 'RETURN_REGISTERED'
}

export enum OrderType {
  ProductOrder = 'PRODUCT_ORDER',
  TourOrder = 'TOUR_ORDER'
}

export type OrderVerifyFullyPaidPayload = {
  __typename?: 'OrderVerifyFullyPaidPayload';
  fullyPaid: Scalars['Boolean']['output'];
};

export type OrderVoucher = {
  __typename?: 'OrderVoucher';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  type: VoucherType;
  value: Scalars['Int']['output'];
};

export type OrderVoucherOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderVoucherWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type OrderWhereUniqueInput = {
  billingAddressId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  shippingAddressId?: InputMaybe<Scalars['String']['input']>;
  tourBookingId?: InputMaybe<Scalars['String']['input']>;
};

export type OrdersFilterInput = {
  from?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<OrderStatus>>>;
  store?: InputMaybe<Array<InputMaybe<Store>>>;
  till?: InputMaybe<Scalars['String']['input']>;
};

export type OrdersPayload = {
  __typename?: 'OrdersPayload';
  items?: Maybe<Array<Maybe<Order>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type OriginalValueGiftcardInput = {
  id: Scalars['String']['input'];
  securityCode: Scalars['String']['input'];
};

export type PasswordResetOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  validTill?: InputMaybe<SortOrder>;
};

export type PaymentDetailsInput = {
  MD: Scalars['String']['input'];
  PaRes: Scalars['String']['input'];
  paymentData: Scalars['String']['input'];
};

export type PaymentGiftcardInput = {
  /** The amount in cents */
  amount: Scalars['Int']['input'];
  giftcardNumber: Scalars['String']['input'];
  securityCode: Scalars['String']['input'];
};

export type PaymentInput = {
  browserInfo?: InputMaybe<Scalars['Json']['input']>;
  email: Scalars['String']['input'];
  giftcards?: InputMaybe<Array<InputMaybe<PaymentGiftcardInput>>>;
  orderId: Scalars['Int']['input'];
  paymentMethod?: InputMaybe<Scalars['Json']['input']>;
  paypalOrderId?: InputMaybe<Scalars['String']['input']>;
  returnUrl: Scalars['String']['input'];
  shopperIP?: InputMaybe<Scalars['String']['input']>;
};

export type PostalCodeLookupPayload = {
  __typename?: 'PostalCodeLookupPayload';
  city?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  municipality?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type PreOwnedWatchInput = {
  email: Scalars['String']['input'];
  fileIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName: Scalars['String']['input'];
  gender: Gender;
  lastName: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  newsletterSignup?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preOwnedAdditionalItems: LeadAdditionalItems;
  preOwnedBrand: Scalars['String']['input'];
  preOwnedCondition: LeadPreOwnedCondition;
  preOwnedExpectedPrice: Scalars['String']['input'];
  preOwnedModel: Scalars['String']['input'];
  preOwnedReference: Scalars['String']['input'];
  preOwnedTradeType: LeadTradeType;
  preOwnedYear: Scalars['String']['input'];
};

export type PrivilegeCartItem = {
  __typename?: 'PrivilegeCartItem';
  articleId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageSource: Scalars['String']['output'];
  privilegeId: Scalars['String']['output'];
  tax: Scalars['Float']['output'];
  value: Scalars['Int']['output'];
};

export type PrivilegeCartItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PrivilegeOrderItem = {
  __typename?: 'PrivilegeOrderItem';
  article?: Maybe<Article>;
  articleId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageSource: Scalars['String']['output'];
  privilegeId: Scalars['String']['output'];
  tax: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Int']['output'];
};

export type PrivilegeOrderItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type Query = {
  __typename?: 'Query';
  address?: Maybe<Address>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  admin?: Maybe<User>;
  admins?: Maybe<AdminsPayload>;
  article?: Maybe<Article>;
  articles?: Maybe<ArticlesPayload>;
  articlesById?: Maybe<Array<Maybe<Article>>>;
  cart?: Maybe<Cart>;
  checkAccountExistence?: Maybe<Scalars['Boolean']['output']>;
  choicesArticle?: Maybe<ChoicesArticle>;
  choicesArticleCombination?: Maybe<ChoicesArticleCombination>;
  choicesArticleCombinations?: Maybe<ChoicesArticleCombinationsPayload>;
  choicesArticleCombinationsById?: Maybe<Array<ChoicesArticleCombination>>;
  choicesArticles?: Maybe<ChoicesArticlesPayload>;
  choicesArticlesById?: Maybe<Array<ChoicesArticle>>;
  choicesCart?: Maybe<Cart>;
  choicesRelatedCombination?: Maybe<ChoicesRelatedCombination>;
  choicesRelatedCombinations?: Maybe<ChoicesRelatedCombinationsPayload>;
  files: Array<File>;
  filters?: Maybe<FilterPayload>;
  form?: Maybe<Form>;
  formSubmission?: Maybe<FormSubmission>;
  formSubmissions: FormSubmissionsPayload;
  forms: FormsPayload;
  getMyParcelLabelUrl?: Maybe<Scalars['String']['output']>;
  giftcards?: Maybe<Array<Maybe<Giftcard>>>;
  invoice?: Maybe<Invoice>;
  invoices?: Maybe<InvoicesPayload>;
  lead?: Maybe<Lead>;
  leads?: Maybe<LeadsPayload>;
  loyaltyStatus?: Maybe<DafLoyaltyStatus>;
  me?: Maybe<User>;
  order?: Maybe<Order>;
  orderByIdAndEmail?: Maybe<Order>;
  orderForReturn?: Maybe<Order>;
  orders?: Maybe<OrdersPayload>;
  originalValueGiftcard?: Maybe<Scalars['Int']['output']>;
  paymentMethods?: Maybe<Scalars['Json']['output']>;
  postalCodeLookup?: Maybe<PostalCodeLookupPayload>;
  preferences?: Maybe<Array<Maybe<DafPreferenceTypeItem>>>;
  privileges?: Maybe<Array<Maybe<DafPrivilegeTypeItem>>>;
  publicWishlist?: Maybe<UserWishlist>;
  service?: Maybe<DafService>;
  services: ServicesPayload;
  tourByCodeAndEmail?: Maybe<TourBooking>;
  userFile?: Maybe<File>;
  userFiles?: Maybe<FilesPayload>;
  userFormSubmission?: Maybe<FormSubmission>;
  validateCurrentPassword?: Maybe<Scalars['Boolean']['output']>;
  verifyAdyenPayment?: Maybe<Scalars['Boolean']['output']>;
  version: Scalars['String']['output'];
  voucher?: Maybe<Voucher>;
  vouchers?: Maybe<VouchersPayload>;
  wishlist?: Maybe<UserWishlist>;
  wishlists?: Maybe<Array<UserWishlist>>;
};


export type QueryAddressArgs = {
  id: Scalars['String']['input'];
};


export type QueryAdminArgs = {
  id: Scalars['String']['input'];
};


export type QueryAdminsArgs = {
  filter?: InputMaybe<QueryUsersWhereInput>;
  orderBy?: InputMaybe<QueryUsersOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryArticleArgs = {
  id: Scalars['String']['input'];
};


export type QueryArticlesArgs = {
  filters?: InputMaybe<ArticlesFilterInput>;
  pagination?: InputMaybe<ArticlesPaginationInput>;
};


export type QueryArticlesByIdArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type QueryCartArgs = {
  reference?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCheckAccountExistenceArgs = {
  email: Scalars['String']['input'];
};


export type QueryChoicesArticleArgs = {
  id: Scalars['String']['input'];
};


export type QueryChoicesArticleCombinationArgs = {
  id: Scalars['String']['input'];
};


export type QueryChoicesArticleCombinationsArgs = {
  filter?: InputMaybe<ChoicesArticleCombinationsFilter>;
  pagination?: InputMaybe<ChoicesArticleCombinationsPagination>;
};


export type QueryChoicesArticleCombinationsByIdArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type QueryChoicesArticlesArgs = {
  filter: ChoicesArticlesFilter;
  pagination?: InputMaybe<ChoicesArticlesPagination>;
};


export type QueryChoicesArticlesByIdArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type QueryChoicesCartArgs = {
  reference?: InputMaybe<Scalars['String']['input']>;
};


export type QueryChoicesRelatedCombinationArgs = {
  id: Scalars['String']['input'];
};


export type QueryChoicesRelatedCombinationsArgs = {
  filter?: InputMaybe<ChoicesRelatedCombinationsFilter>;
  pagination?: InputMaybe<ChoicesRelatedCombinationsPagination>;
};


export type QueryFilesArgs = {
  after?: InputMaybe<FileWhereUniqueInput>;
  before?: InputMaybe<FileWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<QueryFilesOrderByInput>>;
};


export type QueryFiltersArgs = {
  filters?: InputMaybe<ArticlesFilterInput>;
};


export type QueryFormArgs = {
  id: Scalars['String']['input'];
};


export type QueryFormSubmissionArgs = {
  id: Scalars['String']['input'];
};


export type QueryFormSubmissionsArgs = {
  filter?: InputMaybe<FormSubmissionsFilterInput>;
  formId: Scalars['String']['input'];
  orderBy?: InputMaybe<FormSubmissionsOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFormsArgs = {
  filter?: InputMaybe<FormsFilterInput>;
  orderBy?: InputMaybe<FormsOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetMyParcelLabelUrlArgs = {
  myParcelId: Scalars['String']['input'];
};


export type QueryInvoiceArgs = {
  id: Scalars['String']['input'];
};


export type QueryInvoicesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLeadArgs = {
  id: Scalars['String']['input'];
};


export type QueryLeadsArgs = {
  filter?: InputMaybe<LeadsFilterInput>;
  orderBy?: InputMaybe<QueryLeadsOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrderArgs = {
  id: Scalars['Int']['input'];
};


export type QueryOrderByIdAndEmailArgs = {
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};


export type QueryOrderForReturnArgs = {
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};


export type QueryOrdersArgs = {
  filter?: InputMaybe<OrdersFilterInput>;
  orderBy?: InputMaybe<QueryOrdersOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOriginalValueGiftcardArgs = {
  input: OriginalValueGiftcardInput;
};


export type QueryPaymentMethodsArgs = {
  amount: Scalars['Int']['input'];
};


export type QueryPostalCodeLookupArgs = {
  houseNumber: Scalars['Int']['input'];
  postalCode: Scalars['String']['input'];
};


export type QueryPublicWishlistArgs = {
  id: Scalars['String']['input'];
};


export type QueryServiceArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTourByCodeAndEmailArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type QueryUserFileArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserFilesArgs = {
  orderBy?: InputMaybe<QueryFilesOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserFormSubmissionArgs = {
  formId: Scalars['String']['input'];
};


export type QueryValidateCurrentPasswordArgs = {
  password: Scalars['String']['input'];
};


export type QueryVerifyAdyenPaymentArgs = {
  payload: Scalars['String']['input'];
};


export type QueryVoucherArgs = {
  id: Scalars['String']['input'];
};


export type QueryVouchersArgs = {
  filter?: InputMaybe<VouchersFilterInput>;
  orderBy?: InputMaybe<QueryVouchersOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWishlistArgs = {
  id: Scalars['String']['input'];
};

export type QueryFilesOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type QueryLeadsOrderByInput = {
  id?: InputMaybe<SortOrder>;
  leadStore?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  referrerTitle?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type QueryOrdersOrderByInput = {
  contactFullName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  store?: InputMaybe<SortOrder>;
  totalPriceIncTax?: InputMaybe<SortOrder>;
};

export type QueryUsersOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastLogin?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryUsersWhereInput = {
  id?: InputMaybe<StringFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  status?: InputMaybe<EnumUserStatusFilter>;
};

export type QueryVouchersOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  store?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  validTill?: InputMaybe<SortOrder>;
};

export type QueryVouchersWhereInput = {
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumVoucherStatusFilter>;
  store?: InputMaybe<EnumStoreFilter>;
  type?: InputMaybe<EnumVoucherTypeFilter>;
};

export type RedirectUrlsInput = {
  de: Scalars['URL']['input'];
  en: Scalars['URL']['input'];
  nl: Scalars['URL']['input'];
};

export type RemovePrivilegeItemFromCartInput = {
  privilegeCartItemId: Scalars['String']['input'];
  reference: Scalars['String']['input'];
};

export type ReturnItem = {
  id?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type ReturnOrderRequestInput = {
  email: Scalars['String']['input'];
  items: Array<ReturnItem>;
  orderId: Scalars['Int']['input'];
};

export enum Role {
  Admin = 'ADMIN',
  User = 'USER'
}

export type ServicesPayload = {
  __typename?: 'ServicesPayload';
  items: Array<DafService>;
  total: Scalars['Int']['output'];
};

export type SinglePreferenceInput = {
  description: Scalars['String']['input'];
  isSelected: Scalars['Boolean']['input'];
  preference_no: Scalars['Int']['input'];
  profile: Scalars['String']['input'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Store {
  Choices = 'CHOICES',
  Gassan = 'GASSAN',
  PreOwned = 'PRE_OWNED'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type TourBooking = {
  __typename?: 'TourBooking';
  client: TourBookingClient;
  clientName?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  countryOfOrigin?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  groupType?: Maybe<Scalars['String']['output']>;
  hotel?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  localGuide?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  persons: Scalars['Int']['output'];
  phone: Scalars['String']['output'];
  prefferedLanguage: Scalars['String']['output'];
  status: TourBookingStatus;
  time: Scalars['String']['output'];
  totalPrice: Scalars['Int']['output'];
  tourLeader?: Maybe<Scalars['String']['output']>;
  travelAgency?: Maybe<Scalars['String']['output']>;
  type: TourBookingType;
};

export enum TourBookingClient {
  Client = 'CLIENT',
  TourOperator = 'TOUR_OPERATOR'
}

export type TourBookingOrderByWithRelationInput = {
  client?: InputMaybe<SortOrder>;
  clientName?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  countryOfOrigin?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  groupType?: InputMaybe<SortOrder>;
  hotel?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  localGuide?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  persons?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  prefferedLanguage?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  time?: InputMaybe<SortOrder>;
  totalPrice?: InputMaybe<SortOrder>;
  tourLeader?: InputMaybe<SortOrder>;
  travelAgency?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum TourBookingStatus {
  Complete = 'COMPLETE',
  WaitingForPayment = 'WAITING_FOR_PAYMENT'
}

export enum TourBookingType {
  Free = 'FREE',
  Payed = 'PAYED'
}

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  paymentMethod?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  type: TransactionType;
};

export type TransactionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum TransactionType {
  Adyen = 'ADYEN',
  Giftcard = 'GIFTCARD',
  Paypal = 'PAYPAL'
}

export type TransactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAdminInput = {
  access?: InputMaybe<Array<InputMaybe<UserAccess>>>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFormInput = {
  adminEmailRecipient?: InputMaybe<Scalars['String']['input']>;
  adminEmailSubject?: InputMaybe<Scalars['String']['input']>;
  autoReplyTemplates?: InputMaybe<Scalars['JSON']['input']>;
  customNewsletterText?: InputMaybe<Scalars['JSON']['input']>;
  customNumberOfPersons?: InputMaybe<Scalars['Int']['input']>;
  fields?: InputMaybe<Array<FormField>>;
  limitToOneSubmission?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  redirectAfterSubmission?: InputMaybe<RedirectUrlsInput>;
  selectableDates?: InputMaybe<Array<Scalars['String']['input']>>;
  selectableTimes?: InputMaybe<Array<Scalars['String']['input']>>;
  store: FormStore;
};

export type UpdateNewsletterOptinInput = {
  email: Scalars['String']['input'];
  optin100: Scalars['Boolean']['input'];
  optin101: Scalars['Boolean']['input'];
  optin102: Scalars['Boolean']['input'];
  reason: Scalars['String']['input'];
};

export type UpdatePartnerInfoInput = {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePreferencesInput = {
  preference_type_description: Scalars['String']['input'];
  preference_type_id: Scalars['Int']['input'];
  preferences: Array<SinglePreferenceInput>;
};

export type UpdateProfileInput = {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  ringSizeLeft?: InputMaybe<Scalars['Int']['input']>;
  ringSizeRight?: InputMaybe<Scalars['Int']['input']>;
  weddingDate?: InputMaybe<Scalars['String']['input']>;
  wristSize?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateVoucherInput = {
  description: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  limitToBrands?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  minimalOrderValue?: InputMaybe<Scalars['Int']['input']>;
  status: VoucherStatus;
  store: Store;
  type: VoucherType;
  validTill?: InputMaybe<Scalars['Date']['input']>;
  value: Scalars['Int']['input'];
};

export type UploadFileInput = {
  name: Scalars['String']['input'];
  type: FileType;
};

export type User = {
  __typename?: 'User';
  access: Array<UserAccess>;
  cart?: Maybe<Cart>;
  createdAt: Scalars['DateTime']['output'];
  dafUser?: Maybe<DafUser>;
  dafUserId?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  role: Role;
  status: UserStatus;
  updatedAt: Scalars['DateTime']['output'];
  wishlist: Array<Scalars['String']['output']>;
};

export enum UserAccess {
  Admin = 'ADMIN',
  Form = 'FORM',
  Lead = 'LEAD',
  Order = 'ORDER',
  PreOwnedLead = 'PRE_OWNED_LEAD',
  PreOwnedOrder = 'PRE_OWNED_ORDER',
  Voucher = 'VOUCHER'
}

export type UserOrderByWithRelationInput = {
  CreatedForms?: InputMaybe<FormOrderByRelationAggregateInput>;
  FormSubmissions?: InputMaybe<FormSubmissionOrderByRelationAggregateInput>;
  access?: InputMaybe<SortOrder>;
  activationKey?: InputMaybe<SortOrder>;
  activityLogEntries?: InputMaybe<ActivityLogEntryOrderByRelationAggregateInput>;
  addresses?: InputMaybe<AddressOrderByRelationAggregateInput>;
  cart?: InputMaybe<CartOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdVouchers?: InputMaybe<VoucherOrderByRelationAggregateInput>;
  dafUserId?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  files?: InputMaybe<FileOrderByRelationAggregateInput>;
  firstName?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  giftcards?: InputMaybe<GiftcardOrderByRelationAggregateInput>;
  hash?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastLogin?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  leadResponses?: InputMaybe<LeadResponseOrderByRelationAggregateInput>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  passwordReset?: InputMaybe<PasswordResetOrderByWithRelationInput>;
  refreshKey?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  salt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  wishlist?: InputMaybe<SortOrder>;
  wishlists?: InputMaybe<UserWishlistOrderByRelationAggregateInput>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Deleted = 'DELETED',
  Pending = 'PENDING'
}

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type UserWishlist = {
  __typename?: 'UserWishlist';
  articleIds: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status: UserWishlistStatus;
};

export type UserWishlistOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum UserWishlistStatus {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type ValidateGiftCardInput = {
  giftcardNumber: Scalars['String']['input'];
  securityCode: Scalars['String']['input'];
};

export type ValidateGiftCardsInput = {
  giftcardNumber: Scalars['String']['input'];
  securityCode: Scalars['String']['input'];
};

export type ValidateGiftcardsPayload = {
  __typename?: 'ValidateGiftcardsPayload';
  appliedGiftcards?: Maybe<Array<Maybe<AppliedGiftcards>>>;
  totalAmount?: Maybe<Scalars['Int']['output']>;
};

export type Voucher = {
  __typename?: 'Voucher';
  activityLog: Array<ActivityLogEntry>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  limit: Scalars['Int']['output'];
  limitToBrands: Array<Scalars['String']['output']>;
  minimalOrderValue: Scalars['Int']['output'];
  status: VoucherStatus;
  store: Store;
  type: VoucherType;
  updatedAt: Scalars['DateTime']['output'];
  used: Scalars['Int']['output'];
  validTill: Scalars['DateTime']['output'];
  value: Scalars['Int']['output'];
};


export type VoucherActivityLogArgs = {
  after?: InputMaybe<ActivityLogEntryWhereUniqueInput>;
  before?: InputMaybe<ActivityLogEntryWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActivityLogEntryOrderByWithRelationInput>>;
};

export type VoucherOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type VoucherOrderByWithRelationInput = {
  activityLog?: InputMaybe<ActivityLogEntryOrderByRelationAggregateInput>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByWithRelationInput>;
  createdById?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
  limitToBrands?: InputMaybe<SortOrder>;
  minimalOrderValue?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  store?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  used?: InputMaybe<SortOrder>;
  validTill?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum VoucherStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Expired = 'EXPIRED',
  Inactive = 'INACTIVE',
  UsedUp = 'USED_UP'
}

export enum VoucherType {
  Absolute = 'ABSOLUTE',
  Percentage = 'PERCENTAGE'
}

export type VoucherWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type VouchersFilterInput = {
  query?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<VoucherStatus>>>;
  type?: InputMaybe<Array<InputMaybe<VoucherType>>>;
  validTill?: InputMaybe<Scalars['Date']['input']>;
};

export type VouchersPayload = {
  __typename?: 'VouchersPayload';
  items?: Maybe<Array<Maybe<Voucher>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput;
  url: Scalars['String']['input'];
  wishlist?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  cartReference?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateAccountMutation = { __typename?: 'Mutation', createAccount?: boolean | null };

export type CheckAccountExistenceQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type CheckAccountExistenceQuery = { __typename?: 'Query', checkAccountExistence?: boolean | null };

export type ProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email: string, dafUserId?: string | null, cart?: { __typename?: 'Cart', id: string } | null, dafUser?: { __typename?: 'DAFUser', klant_nr?: number | null, my_loyalty?: string | null, voornaam?: string | null, Klantnaam?: string | null, geslacht?: string | null, mobiel?: string | null, geboortedatum?: string | null, trouwdatum?: string | null, loyalty_progress?: number | null, ringmaat_links?: number | null, ringmaat_rechts?: number | null, polsmaat?: number | null, pnaam?: string | null, pvoorletters?: string | null, pvoornaam?: string | null, pgeslacht?: string | null, pgeboortedatum?: string | null } | null } | null };

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile?: { __typename?: 'User', id: string, email: string, dafUserId?: string | null, cart?: { __typename?: 'Cart', id: string } | null, dafUser?: { __typename?: 'DAFUser', klant_nr?: number | null, my_loyalty?: string | null, voornaam?: string | null, Klantnaam?: string | null, geslacht?: string | null, mobiel?: string | null, geboortedatum?: string | null, trouwdatum?: string | null, loyalty_progress?: number | null, ringmaat_links?: number | null, ringmaat_rechts?: number | null, polsmaat?: number | null, pnaam?: string | null, pvoorletters?: string | null, pvoornaam?: string | null, pgeslacht?: string | null, pgeboortedatum?: string | null } | null } | null };

export type UpdatePartnerInfoMutationVariables = Exact<{
  input: UpdatePartnerInfoInput;
}>;


export type UpdatePartnerInfoMutation = { __typename?: 'Mutation', updatePartnerInfo?: { __typename?: 'User', id: string, email: string, dafUserId?: string | null, cart?: { __typename?: 'Cart', id: string } | null, dafUser?: { __typename?: 'DAFUser', klant_nr?: number | null, my_loyalty?: string | null, voornaam?: string | null, Klantnaam?: string | null, geslacht?: string | null, mobiel?: string | null, geboortedatum?: string | null, trouwdatum?: string | null, loyalty_progress?: number | null, ringmaat_links?: number | null, ringmaat_rechts?: number | null, polsmaat?: number | null, pnaam?: string | null, pvoorletters?: string | null, pvoornaam?: string | null, pgeslacht?: string | null, pgeboortedatum?: string | null } | null } | null };

export type ValidateCurrentPasswordQueryVariables = Exact<{
  password: Scalars['String']['input'];
}>;


export type ValidateCurrentPasswordQuery = { __typename?: 'Query', validateCurrentPassword?: boolean | null };

export type UpdatePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword?: boolean | null };

export type UserPreferenceFieldsFragment = { __typename?: 'User', id: string, dafUser?: { __typename?: 'DAFUser', optins?: Array<{ __typename?: 'DAFUserOptin', optin?: string | null, value?: boolean | null } | null> | null } | null };

export type NewsletterPreferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type NewsletterPreferencesQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, dafUser?: { __typename?: 'DAFUser', optins?: Array<{ __typename?: 'DAFUserOptin', optin?: string | null, value?: boolean | null } | null> | null } | null } | null };

export type UpdateNewsletterPreferencesMutationVariables = Exact<{
  optins: Array<DafOptins> | DafOptins;
}>;


export type UpdateNewsletterPreferencesMutation = { __typename?: 'Mutation', updateNewsletterPreferences?: { __typename?: 'User', id: string, dafUser?: { __typename?: 'DAFUser', optins?: Array<{ __typename?: 'DAFUserOptin', optin?: string | null, value?: boolean | null } | null> | null } | null } | null };

export type DafUserPreferenceFieldsFragment = { __typename?: 'DAFPreferenceTypeItem', preference_type_id?: number | null, preference_type_description?: string | null, preferences?: Array<{ __typename?: 'DAFPreferenceItem', preference_no?: number | null, profile?: string | null, isSelected?: boolean | null, description?: string | null } | null> | null };

export type PreferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type PreferencesQuery = { __typename?: 'Query', preferences?: Array<{ __typename?: 'DAFPreferenceTypeItem', preference_type_id?: number | null, preference_type_description?: string | null, preferences?: Array<{ __typename?: 'DAFPreferenceItem', preference_no?: number | null, profile?: string | null, isSelected?: boolean | null, description?: string | null } | null> | null } | null> | null };

export type UpdatePreferencesMutationVariables = Exact<{
  input: Array<UpdatePreferencesInput> | UpdatePreferencesInput;
}>;


export type UpdatePreferencesMutation = { __typename?: 'Mutation', updatePreferences?: Array<{ __typename?: 'DAFPreferenceTypeItem', preference_type_id?: number | null, preference_type_description?: string | null, preferences?: Array<{ __typename?: 'DAFPreferenceItem', preference_no?: number | null, profile?: string | null, isSelected?: boolean | null, description?: string | null } | null> | null } | null> | null };

export type UpdateBrandPreferencesMutationVariables = Exact<{
  input: Array<UpdatePreferencesInput> | UpdatePreferencesInput;
}>;


export type UpdateBrandPreferencesMutation = { __typename?: 'Mutation', updatePreferences?: Array<{ __typename?: 'DAFPreferenceTypeItem', preference_type_id?: number | null, preference_type_description?: string | null, preferences?: Array<{ __typename?: 'DAFPreferenceItem', preference_no?: number | null, profile?: string | null, isSelected?: boolean | null, description?: string | null } | null> | null } | null> | null };

export type DafUserPrivilegeFieldsFragment = { __typename?: 'DAFPrivilegeTypeItem', artikel_nr?: number | null, beschikbaar?: number | null, description?: string | null, max_aantal?: number | null, privilege_nr?: number | null, privilege_action?: string | null, preference_type_description?: DafPrivilegeType | null };

export type PrivilegesQueryVariables = Exact<{ [key: string]: never; }>;


export type PrivilegesQuery = { __typename?: 'Query', privileges?: Array<{ __typename?: 'DAFPrivilegeTypeItem', artikel_nr?: number | null, beschikbaar?: number | null, description?: string | null, max_aantal?: number | null, privilege_nr?: number | null, privilege_action?: string | null, preference_type_description?: DafPrivilegeType | null } | null> | null };

export type LoyaltyStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type LoyaltyStatusQuery = { __typename?: 'Query', loyaltyStatus?: { __typename?: 'DAFLoyaltyStatus', loyalty_nr?: number | null, loyalty_description?: string | null, loyalty_progress?: number | null } | null };

export type ServiceFieldsFragment = { __typename?: 'DAFService', service_nr: number, merk: string, model_nr: string, serial_nr: string, date: string, maxprice: number, ttservice_tracking: Array<{ __typename?: 'DAFServiceStep', status_nr: number, status_name: string, service_nr: number, tijd: string, date: string }> };

export type GetServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetServicesQuery = { __typename?: 'Query', services: { __typename?: 'ServicesPayload', total: number, items: Array<{ __typename?: 'DAFService', service_nr: number, merk: string, model_nr: string, serial_nr: string, date: string, maxprice: number, ttservice_tracking: Array<{ __typename?: 'DAFServiceStep', status_nr: number, status_name: string, service_nr: number, tijd: string, date: string }> }> } };

export type GetServiceQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetServiceQuery = { __typename?: 'Query', service?: { __typename?: 'DAFService', service_nr: number, merk: string, model_nr: string, serial_nr: string, date: string, maxprice: number, ttservice_tracking: Array<{ __typename?: 'DAFServiceStep', status_nr: number, status_name: string, service_nr: number, tijd: string, date: string }> } | null };

export type AddressFieldsFragment = { __typename?: 'Address', id: string, createdAt: any, updatedAt: any, gender: Gender, firstName: string, lastName: string, companyName?: string | null, country: string, state?: string | null, postalCode: string, street: string, city: string, houseNumber: string, houseNumberSuffix?: string | null, isBillingAddress: boolean, isShippingAddress: boolean };

export type AddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type AddressesQuery = { __typename?: 'Query', addresses?: Array<{ __typename?: 'Address', id: string, createdAt: any, updatedAt: any, gender: Gender, firstName: string, lastName: string, companyName?: string | null, country: string, state?: string | null, postalCode: string, street: string, city: string, houseNumber: string, houseNumberSuffix?: string | null, isBillingAddress: boolean, isShippingAddress: boolean } | null> | null };

export type DeleteAddressMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteAddressMutation = { __typename?: 'Mutation', deleteAddress?: boolean | null };

export type UpdateAddressMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: AddressInput;
}>;


export type UpdateAddressMutation = { __typename?: 'Mutation', updateAddress?: { __typename?: 'Address', id: string, createdAt: any, updatedAt: any, gender: Gender, firstName: string, lastName: string, companyName?: string | null, country: string, state?: string | null, postalCode: string, street: string, city: string, houseNumber: string, houseNumberSuffix?: string | null, isBillingAddress: boolean, isShippingAddress: boolean } | null };

export type AddAddressMutationVariables = Exact<{
  input: AddressInput;
}>;


export type AddAddressMutation = { __typename?: 'Mutation', addAddress?: { __typename?: 'Address', id: string, createdAt: any, updatedAt: any, gender: Gender, firstName: string, lastName: string, companyName?: string | null, country: string, state?: string | null, postalCode: string, street: string, city: string, houseNumber: string, houseNumberSuffix?: string | null, isBillingAddress: boolean, isShippingAddress: boolean } | null };

export type AdminEventFormQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AdminEventFormQuery = { __typename?: 'Query', form?: { __typename?: 'Form', name: string, note?: string | null, fields: Array<FormField>, selectableDates: Array<string>, selectableTimes: Array<string>, customNewsletterText?: any | null, customNumberOfPersons?: number | null, redirectAfterSubmission?: any | null } | null };

export type ArticleFragmentFragment = { __typename?: 'Article', id?: string | null, webID?: string | null, price?: number | null, salePrice?: number | null, images?: Array<string | null> | null, brand?: string | null, modelName?: string | null, description?: string | null, urlSlug?: string | null };

export type ArticleQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ArticleQuery = { __typename?: 'Query', article?: { __typename?: 'Article', supplierNr?: string | null, description?: string | null, collection?: string | null, modelName?: string | null, eancode?: string | null, articleType?: string | null, articleTypeID?: number | null, inStock?: boolean | null, isWebshopSale?: boolean | null, ignoreStockCheck?: boolean | null, isPriceVisible?: boolean | null, isWebShopOnly?: boolean | null, isOutOfCollection?: boolean | null, collectionNumber?: number | null, id?: string | null, webID?: string | null, price?: number | null, salePrice?: number | null, images?: Array<string | null> | null, brand?: string | null, urlSlug?: string | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeName?: string | null, attributeID?: number | null, attributeValueID?: number | null, attributeValue?: string | null, attributeOriginalName?: string | null } | null> | null } | null };

export type ArticleListFragmentFragment = { __typename: 'Article', id?: string | null, webID?: string | null, price?: number | null, salePrice?: number | null, images?: Array<string | null> | null, brand?: string | null, modelName?: string | null, description?: string | null, urlSlug?: string | null, articleType?: string | null, articleTypeID?: number | null, isPriceVisible?: boolean | null, collection?: string | null, collectionNumber?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeName?: string | null, attributeID?: number | null, attributeValueID?: number | null, attributeValue?: string | null, attributeOriginalName?: string | null } | null> | null };

export type ArticlesQueryVariables = Exact<{
  pagination?: InputMaybe<ArticlesPaginationInput>;
  filters?: InputMaybe<ArticlesFilterInput>;
}>;


export type ArticlesQuery = { __typename?: 'Query', articles?: { __typename?: 'ArticlesPayload', total?: number | null, items?: Array<{ __typename: 'Article', id?: string | null, webID?: string | null, price?: number | null, salePrice?: number | null, images?: Array<string | null> | null, brand?: string | null, modelName?: string | null, description?: string | null, urlSlug?: string | null, articleType?: string | null, articleTypeID?: number | null, isPriceVisible?: boolean | null, collection?: string | null, collectionNumber?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeName?: string | null, attributeID?: number | null, attributeValueID?: number | null, attributeValue?: string | null, attributeOriginalName?: string | null } | null> | null } | null> | null } | null };

export type FiltersQueryVariables = Exact<{
  filters?: InputMaybe<ArticlesFilterInput>;
}>;


export type FiltersQuery = { __typename?: 'Query', filters?: { __typename?: 'FilterPayload', articleTypeFilters?: Array<{ __typename?: 'ArticleTypeFilter', articleTypeID?: number | null, articleType?: string | null } | null> | null, brandFilters?: Array<{ __typename?: 'BrandTypeFilter', merk_nr?: number | null, brand?: string | null } | null> | null, brandCollectionFilters?: Array<{ __typename?: 'BrandCollectionFilter', collection?: string | null, collectionNumber?: number | null } | null> | null, articleAttributeFilters?: Array<{ __typename?: 'ArticleAttributeFilter', attributeID?: number | null, attributeName?: string | null, articleFilterAttributeValues?: Array<{ __typename?: 'ArticleFilterAttributeValue', attributeValueID?: number | null, attributeValue?: string | null } | null> | null } | null> | null } | null };

export type ArticlesByIdFragmentFragment = { __typename?: 'Article', id?: string | null, price?: number | null, salePrice?: number | null, images?: Array<string | null> | null, brand?: string | null, modelName?: string | null, description?: string | null, urlSlug?: string | null };

export type ArticlesByIdQueryVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ArticlesByIdQuery = { __typename?: 'Query', articlesById?: Array<{ __typename?: 'Article', articleType?: string | null, inStock?: boolean | null, isWebshopSale?: boolean | null, ignoreStockCheck?: boolean | null, isPriceVisible?: boolean | null, isWebShopOnly?: boolean | null, isOutOfCollection?: boolean | null, id?: string | null, price?: number | null, salePrice?: number | null, images?: Array<string | null> | null, brand?: string | null, modelName?: string | null, description?: string | null, urlSlug?: string | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeName?: string | null, attributeValue?: string | null, attributeOriginalName?: string | null } | null> | null } | null> | null };

export type UserFieldsFragment = { __typename?: 'User', id: string, email: string, dafUserId?: string | null, cart?: { __typename?: 'Cart', id: string } | null, dafUser?: { __typename?: 'DAFUser', klant_nr?: number | null, my_loyalty?: string | null, voornaam?: string | null, Klantnaam?: string | null, geslacht?: string | null, mobiel?: string | null, geboortedatum?: string | null, trouwdatum?: string | null, loyalty_progress?: number | null, ringmaat_links?: number | null, ringmaat_rechts?: number | null, polsmaat?: number | null, pnaam?: string | null, pvoorletters?: string | null, pvoornaam?: string | null, pgeslacht?: string | null, pgeboortedatum?: string | null } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email: string, dafUserId?: string | null, cart?: { __typename?: 'Cart', id: string } | null, dafUser?: { __typename?: 'DAFUser', klant_nr?: number | null, my_loyalty?: string | null, voornaam?: string | null, Klantnaam?: string | null, geslacht?: string | null, mobiel?: string | null, geboortedatum?: string | null, trouwdatum?: string | null, loyalty_progress?: number | null, ringmaat_links?: number | null, ringmaat_rechts?: number | null, polsmaat?: number | null, pnaam?: string | null, pvoorletters?: string | null, pvoornaam?: string | null, pgeslacht?: string | null, pgeboortedatum?: string | null } | null } | null };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'LoginPayload', authToken?: string | null, refreshToken?: string | null, user?: { __typename?: 'User', id: string, email: string, dafUserId?: string | null, cart?: { __typename?: 'Cart', id: string } | null, dafUser?: { __typename?: 'DAFUser', klant_nr?: number | null, my_loyalty?: string | null, voornaam?: string | null, Klantnaam?: string | null, geslacht?: string | null, mobiel?: string | null, geboortedatum?: string | null, trouwdatum?: string | null, loyalty_progress?: number | null, ringmaat_links?: number | null, ringmaat_rechts?: number | null, polsmaat?: number | null, pnaam?: string | null, pvoorletters?: string | null, pvoornaam?: string | null, pgeslacht?: string | null, pgeboortedatum?: string | null } | null } | null } | null };

export type RenewTokenMutationVariables = Exact<{
  authToken: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
}>;


export type RenewTokenMutation = { __typename?: 'Mutation', renewToken?: { __typename?: 'LoginPayload', authToken?: string | null, refreshToken?: string | null } | null };

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
  url: Scalars['String']['input'];
}>;


export type RequestResetPasswordMutation = { __typename?: 'Mutation', requestResetPassword?: boolean | null };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'LoginPayload', authToken?: string | null, refreshToken?: string | null, user?: { __typename?: 'User', id: string, email: string, dafUserId?: string | null, cart?: { __typename?: 'Cart', id: string } | null, dafUser?: { __typename?: 'DAFUser', klant_nr?: number | null, my_loyalty?: string | null, voornaam?: string | null, Klantnaam?: string | null, geslacht?: string | null, mobiel?: string | null, geboortedatum?: string | null, trouwdatum?: string | null, loyalty_progress?: number | null, ringmaat_links?: number | null, ringmaat_rechts?: number | null, polsmaat?: number | null, pnaam?: string | null, pvoorletters?: string | null, pvoornaam?: string | null, pgeslacht?: string | null, pgeboortedatum?: string | null } | null } | null } | null };

export type CartFragmentFragment = { __typename?: 'Cart', id: string, totalTax?: number | null, totalPriceExTax?: number | null, totalPriceIncTax?: number | null, totalItems?: number | null, shippingCosts: number, vouchers: Array<{ __typename?: 'CartVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'CartItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeCartItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null };

export type CartTotalItemsQueryVariables = Exact<{
  reference: Scalars['String']['input'];
}>;


export type CartTotalItemsQuery = { __typename?: 'Query', cart?: { __typename?: 'Cart', id: string, totalItems?: number | null } | null };

export type CartQueryVariables = Exact<{
  reference?: InputMaybe<Scalars['String']['input']>;
}>;


export type CartQuery = { __typename?: 'Query', cart?: { __typename?: 'Cart', id: string, totalTax?: number | null, totalPriceExTax?: number | null, totalPriceIncTax?: number | null, totalItems?: number | null, shippingCosts: number, vouchers: Array<{ __typename?: 'CartVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'CartItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeCartItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null } | null };

export type DeleteCartMutationVariables = Exact<{
  reference: Scalars['String']['input'];
}>;


export type DeleteCartMutation = { __typename?: 'Mutation', deleteCart?: boolean | null };

export type AddArticleToCartMutationVariables = Exact<{
  reference: Scalars['String']['input'];
  articleId: Scalars['String']['input'];
  size?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddArticleToCartMutation = { __typename?: 'Mutation', addArticleToCart?: { __typename?: 'Cart', id: string, totalTax?: number | null, totalPriceExTax?: number | null, totalPriceIncTax?: number | null, totalItems?: number | null, shippingCosts: number, vouchers: Array<{ __typename?: 'CartVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'CartItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeCartItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null } | null };

export type AddGiftcardToCartMutationVariables = Exact<{
  reference: Scalars['String']['input'];
  amount: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AddGiftcardToCartMutation = { __typename?: 'Mutation', addGiftcardToCart?: { __typename?: 'Cart', id: string, totalTax?: number | null, totalPriceExTax?: number | null, totalPriceIncTax?: number | null, totalItems?: number | null, shippingCosts: number, vouchers: Array<{ __typename?: 'CartVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'CartItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeCartItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null } | null };

export type RemoveItemFromCartMutationVariables = Exact<{
  reference: Scalars['String']['input'];
  cartItemId: Scalars['String']['input'];
}>;


export type RemoveItemFromCartMutation = { __typename?: 'Mutation', removeItemFromCart?: { __typename?: 'Cart', id: string, totalTax?: number | null, totalPriceExTax?: number | null, totalPriceIncTax?: number | null, totalItems?: number | null, shippingCosts: number, vouchers: Array<{ __typename?: 'CartVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'CartItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeCartItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null } | null };

export type UpdateCartItemQuantityMutationVariables = Exact<{
  reference: Scalars['String']['input'];
  cartItemId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
}>;


export type UpdateCartItemQuantityMutation = { __typename?: 'Mutation', updateCartItemQuantity?: { __typename?: 'Cart', id: string, totalTax?: number | null, totalPriceExTax?: number | null, totalPriceIncTax?: number | null, totalItems?: number | null, shippingCosts: number, vouchers: Array<{ __typename?: 'CartVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'CartItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeCartItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null } | null };

export type UpdateCartItemSizeMutationVariables = Exact<{
  reference: Scalars['String']['input'];
  cartItemId: Scalars['String']['input'];
  size?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateCartItemSizeMutation = { __typename?: 'Mutation', updateCartItemSize?: { __typename?: 'Cart', id: string, totalTax?: number | null, totalPriceExTax?: number | null, totalPriceIncTax?: number | null, totalItems?: number | null, shippingCosts: number, vouchers: Array<{ __typename?: 'CartVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'CartItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeCartItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null } | null };

export type UpdateShippingCostsMutationVariables = Exact<{
  reference: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
}>;


export type UpdateShippingCostsMutation = { __typename?: 'Mutation', updateShippingCosts?: { __typename?: 'Cart', id: string, totalTax?: number | null, totalPriceExTax?: number | null, totalPriceIncTax?: number | null, totalItems?: number | null, shippingCosts: number, vouchers: Array<{ __typename?: 'CartVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'CartItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeCartItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null } | null };

export type AddVoucherMutationVariables = Exact<{
  reference: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;


export type AddVoucherMutation = { __typename?: 'Mutation', addVoucher?: { __typename?: 'Cart', id: string, totalTax?: number | null, totalPriceExTax?: number | null, totalPriceIncTax?: number | null, totalItems?: number | null, shippingCosts: number, vouchers: Array<{ __typename?: 'CartVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'CartItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeCartItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null } | null };

export type RemoveVoucherMutationVariables = Exact<{
  reference: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;


export type RemoveVoucherMutation = { __typename?: 'Mutation', removeVoucher?: { __typename?: 'Cart', id: string, totalTax?: number | null, totalPriceExTax?: number | null, totalPriceIncTax?: number | null, totalItems?: number | null, shippingCosts: number, vouchers: Array<{ __typename?: 'CartVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'CartItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeCartItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null } | null };

export type ClearCartMutationVariables = Exact<{
  reference: Scalars['String']['input'];
}>;


export type ClearCartMutation = { __typename?: 'Mutation', clearCart?: { __typename?: 'Cart', id: string, totalTax?: number | null, totalPriceExTax?: number | null, totalPriceIncTax?: number | null, totalItems?: number | null, shippingCosts: number, vouchers: Array<{ __typename?: 'CartVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'CartItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeCartItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null } | null };

export type AddPrivilegeToCartMutationVariables = Exact<{
  input: AddPrivilegeToCartInput;
}>;


export type AddPrivilegeToCartMutation = { __typename?: 'Mutation', addPrivilegeToCart?: { __typename?: 'Cart', id: string, totalTax?: number | null, totalPriceExTax?: number | null, totalPriceIncTax?: number | null, totalItems?: number | null, shippingCosts: number, vouchers: Array<{ __typename?: 'CartVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'CartItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeCartItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null } | null };

export type RemovePrivilegeItemFromCartMutationVariables = Exact<{
  input: RemovePrivilegeItemFromCartInput;
}>;


export type RemovePrivilegeItemFromCartMutation = { __typename?: 'Mutation', removePrivilegeItemFromCart?: { __typename?: 'Cart', id: string, totalTax?: number | null, totalPriceExTax?: number | null, totalPriceIncTax?: number | null, totalItems?: number | null, shippingCosts: number, vouchers: Array<{ __typename?: 'CartVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'CartItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeCartItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null } | null };

export type CdcLoginMutationVariables = Exact<{
  input: CdcLoginInput;
}>;


export type CdcLoginMutation = { __typename?: 'Mutation', cdcLogin: boolean };

export type CdcCompleteSocialLoginMutationVariables = Exact<{
  input: CdcCompleteSocialLoginInput;
}>;


export type CdcCompleteSocialLoginMutation = { __typename?: 'Mutation', cdcCompleteSocialLogin: boolean };

export type CdcRequestResendVerificationCodeMutationVariables = Exact<{
  input: CdcRequestResendVerificationCodeInput;
}>;


export type CdcRequestResendVerificationCodeMutation = { __typename?: 'Mutation', cdcRequestResendVerificationCode: boolean };

export type OrderFieldsFragment = { __typename?: 'Order', id: number, status: OrderStatus, contactEmail: string, contactPhone: string, totalPriceIncTax: number, totalPriceExTax: number, totalTax: number, shippingCosts: number, createdAt: any, vouchers: Array<{ __typename?: 'OrderVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'OrderItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeOrderItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null, shippingAddress: { __typename?: 'OrderAddress', firstName: string, lastName: string } };

export type CheckoutMutationVariables = Exact<{
  reference: Scalars['String']['input'];
  input: CheckoutInput;
  meta?: InputMaybe<CheckoutMetaInput>;
}>;


export type CheckoutMutation = { __typename?: 'Mutation', checkout?: { __typename?: 'Order', id: number, status: OrderStatus } | null };

export type OrderByIdAndEmailQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  email: Scalars['String']['input'];
}>;


export type OrderByIdAndEmailQuery = { __typename?: 'Query', orderByIdAndEmail?: { __typename?: 'Order', deliveryType: OrderDeliveryType, id: number, status: OrderStatus, contactEmail: string, contactPhone: string, totalPriceIncTax: number, totalPriceExTax: number, totalTax: number, shippingCosts: number, createdAt: any, shippingAddress: { __typename?: 'OrderAddress', id: string, gender: Gender, companyName?: string | null, firstName: string, lastName: string, street: string, houseNumber: string, houseNumberSuffix?: string | null, postalCode: string, city: string, country: string }, billingAddress: { __typename?: 'OrderAddress', id: string, gender: Gender, companyName?: string | null, firstName: string, lastName: string, street: string, houseNumber: string, houseNumberSuffix?: string | null, postalCode: string, city: string, country: string }, transactions: Array<{ __typename?: 'Transaction', type: TransactionType, paymentMethod?: string | null, reference?: string | null, amount: number }>, vouchers: Array<{ __typename?: 'OrderVoucher', id: string, type: VoucherType, code: string, value: number }>, items?: Array<{ __typename?: 'OrderItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, message?: string | null, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null, articleAttributes?: Array<{ __typename?: 'ArticleAttribute', attributeID?: number | null, attributeValue?: string | null } | null> | null } | null } | null> | null, privilegeItems?: Array<{ __typename?: 'PrivilegeOrderItem', id: string, privilegeId: string, imageSource: string, value: number, articleId: string, description: string } | null> | null } | null };

export type PaymentMethodsQueryVariables = Exact<{
  amount: Scalars['Int']['input'];
}>;


export type PaymentMethodsQuery = { __typename?: 'Query', paymentMethods?: any | null };

export type PaymentMutationVariables = Exact<{
  input: PaymentInput;
}>;


export type PaymentMutation = { __typename?: 'Mutation', payment?: any | null };

export type PaymentDetailsMutationVariables = Exact<{
  input: PaymentDetailsInput;
}>;


export type PaymentDetailsMutation = { __typename?: 'Mutation', paymentDetails?: any | null };

export type VerifyAdyenPaymentQueryVariables = Exact<{
  payload: Scalars['String']['input'];
}>;


export type VerifyAdyenPaymentQuery = { __typename?: 'Query', verifyAdyenPayment?: boolean | null };

export type ValidateGiftCardMutationVariables = Exact<{
  reference: Scalars['String']['input'];
  input: ValidateGiftCardInput;
}>;


export type ValidateGiftCardMutation = { __typename?: 'Mutation', validateGiftCard?: number | null };

export type ValidateGiftCardsMutationVariables = Exact<{
  reference: Scalars['String']['input'];
  input: Array<ValidateGiftCardsInput> | ValidateGiftCardsInput;
}>;


export type ValidateGiftCardsMutation = { __typename?: 'Mutation', validateGiftCards?: { __typename?: 'ValidateGiftcardsPayload', totalAmount?: number | null, appliedGiftcards?: Array<{ __typename?: 'AppliedGiftcards', giftcardNumber?: string | null, securityCode?: string | null, appliedAmount?: number | null } | null> | null } | null };

export type CheckoutAddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckoutAddressesQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email: string, dafUser?: { __typename?: 'DAFUser', mobiel?: string | null } | null } | null, addresses?: Array<{ __typename?: 'Address', id: string, createdAt: any, updatedAt: any, gender: Gender, firstName: string, lastName: string, companyName?: string | null, country: string, state?: string | null, postalCode: string, street: string, city: string, houseNumber: string, houseNumberSuffix?: string | null, isBillingAddress: boolean, isShippingAddress: boolean } | null> | null };

export type AdyenCreateCheckoutSessionMutationVariables = Exact<{
  input: AdyenCreateCheckoutSessionInput;
}>;


export type AdyenCreateCheckoutSessionMutation = { __typename?: 'Mutation', adyenCreateCheckoutSession: { __typename?: 'AdyenCreateCheckoutSessionPaymentPayload', sessionId?: string | null, sessionData?: string | null } };

export type OrderVerifyFullyPaidMutationVariables = Exact<{
  orderId: Scalars['Int']['input'];
}>;


export type OrderVerifyFullyPaidMutation = { __typename?: 'Mutation', orderVerifyFullyPaid: { __typename?: 'OrderVerifyFullyPaidPayload', fullyPaid: boolean } };

export type OrderAddVoucherMutationVariables = Exact<{
  orderId: Scalars['Int']['input'];
  voucherCode: Scalars['String']['input'];
}>;


export type OrderAddVoucherMutation = { __typename?: 'Mutation', orderAddVoucher: boolean };

export type OrderAddGiftcardMutationVariables = Exact<{
  orderId: Scalars['Int']['input'];
  input: AddGiftcardToOrderGiftcardInput;
}>;


export type OrderAddGiftcardMutation = { __typename?: 'Mutation', orderAddGiftcard: boolean };

export type OrderRemoveGiftcardMutationVariables = Exact<{
  orderId: Scalars['Int']['input'];
  giftcardNumber: Scalars['String']['input'];
}>;


export type OrderRemoveGiftcardMutation = { __typename?: 'Mutation', orderRemoveGiftcard: boolean };

export type OrderRemoveVoucherMutationVariables = Exact<{
  orderId: Scalars['Int']['input'];
  code: Scalars['String']['input'];
}>;


export type OrderRemoveVoucherMutation = { __typename?: 'Mutation', orderRemoveVoucher: boolean };

export type CreateFormSubmissionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: CreateFormSubmissionInput;
}>;


export type CreateFormSubmissionMutation = { __typename?: 'Mutation', createFormSubmission: { __typename?: 'FormSubmission', id: string } };

export type FileFieldsFragment = { __typename?: 'File', id: string, type: FileType, name: string, createdAt: any };

export type UserFilesQueryVariables = Exact<{
  orderBy?: InputMaybe<QueryFilesOrderByInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UserFilesQuery = { __typename?: 'Query', userFiles?: { __typename?: 'FilesPayload', total?: number | null, items?: Array<{ __typename?: 'File', id: string, type: FileType, name: string, createdAt: any } | null> | null } | null };

export type DeleteUserFileMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserFileMutation = { __typename?: 'Mutation', deleteUserFile?: string | null };

export type DownloadUserFileMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DownloadUserFileMutation = { __typename?: 'Mutation', downloadUserFile?: string | null };

export type UploadUserFileMutationVariables = Exact<{
  input: UploadFileInput;
  upload: Scalars['Upload']['input'];
}>;


export type UploadUserFileMutation = { __typename?: 'Mutation', uploadUserFile?: { __typename?: 'File', id: string, type: FileType, name: string, createdAt: any } | null };

export type FormSubmissionQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type FormSubmissionQuery = { __typename?: 'Query', userFormSubmission?: { __typename?: 'FormSubmission', firstName: string, lastName: string, email: string, values: any } | null };

export type GiftcardFieldsFragment = { __typename?: 'Giftcard', id: string, code: string, originalValue?: number | null };

export type GiftcardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GiftcardsQuery = { __typename?: 'Query', giftcards?: Array<{ __typename?: 'Giftcard', id: string, code: string, originalValue?: number | null } | null> | null };

export type DeleteGiftcardMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteGiftcardMutation = { __typename?: 'Mutation', deleteGiftcard?: boolean | null };

export type AddGiftcardMutationVariables = Exact<{
  input: GiftcardInput;
}>;


export type AddGiftcardMutation = { __typename?: 'Mutation', addGiftcard?: { __typename?: 'Giftcard', id: string, code: string, originalValue?: number | null } | null };

export type OriginalValueGiftcardQueryVariables = Exact<{
  input: OriginalValueGiftcardInput;
}>;


export type OriginalValueGiftcardQuery = { __typename?: 'Query', originalValueGiftcard?: number | null };

export type CheckGiftcardBalanceMutationVariables = Exact<{
  input: CheckGiftcardBalanceInput;
}>;


export type CheckGiftcardBalanceMutation = { __typename?: 'Mutation', checkGiftCardBalance?: number | null };

export type InvoiceFieldsFragment = { __typename?: 'Invoice', id?: string | null, idRef?: string | null, bonnr?: number | null, date?: any | null, discount?: number | null, shipping_cost?: number | null, klant_naam?: string | null, email?: string | null, adres?: string | null, postalcode?: string | null, city?: string | null, country?: string | null, sales_lines?: Array<{ __typename?: 'InvoiceSalesLine', artikel_nr?: string | null, artikel_stuks?: number | null, artikel_bedrag_incl?: number | null, artikel_bedrag_excl?: number | null, description?: string | null } | null> | null, sales_payments?: Array<{ __typename?: 'InvoiceSalesPayment', valutakode?: string | null, betalingsbedrag?: number | null, betalingsbedrag_val?: number | null, betaalwijze?: string | null } | null> | null, order?: { __typename?: 'Order', id: number, status: OrderStatus, billingAddress: { __typename?: 'OrderAddress', postalCode: string, gender: Gender, firstName: string, lastName: string, street: string, houseNumber: string, houseNumberSuffix?: string | null, city: string, country: string }, items?: Array<{ __typename?: 'OrderItem', size?: string | null, quantity: number, article?: { __typename?: 'Article', brand?: string | null, id?: string | null, price?: number | null, salePrice?: number | null, description?: string | null, images?: Array<string | null> | null } | null } | null> | null } | null };

export type InvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoicesQuery = { __typename?: 'Query', invoices?: { __typename?: 'InvoicesPayload', total?: number | null, items?: Array<{ __typename?: 'Invoice', id?: string | null, idRef?: string | null, bonnr?: number | null, date?: any | null, discount?: number | null, shipping_cost?: number | null, klant_naam?: string | null, email?: string | null, adres?: string | null, postalcode?: string | null, city?: string | null, country?: string | null, sales_lines?: Array<{ __typename?: 'InvoiceSalesLine', artikel_nr?: string | null, artikel_stuks?: number | null, artikel_bedrag_incl?: number | null, artikel_bedrag_excl?: number | null, description?: string | null } | null> | null, sales_payments?: Array<{ __typename?: 'InvoiceSalesPayment', valutakode?: string | null, betalingsbedrag?: number | null, betalingsbedrag_val?: number | null, betaalwijze?: string | null } | null> | null, order?: { __typename?: 'Order', id: number, status: OrderStatus, billingAddress: { __typename?: 'OrderAddress', postalCode: string, gender: Gender, firstName: string, lastName: string, street: string, houseNumber: string, houseNumberSuffix?: string | null, city: string, country: string }, items?: Array<{ __typename?: 'OrderItem', size?: string | null, quantity: number, article?: { __typename?: 'Article', brand?: string | null, id?: string | null, price?: number | null, salePrice?: number | null, description?: string | null, images?: Array<string | null> | null } | null } | null> | null } | null } | null> | null } | null };

export type InvoiceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type InvoiceQuery = { __typename?: 'Query', invoice?: { __typename?: 'Invoice', id?: string | null, idRef?: string | null, bonnr?: number | null, date?: any | null, discount?: number | null, shipping_cost?: number | null, klant_naam?: string | null, email?: string | null, adres?: string | null, postalcode?: string | null, city?: string | null, country?: string | null, sales_lines?: Array<{ __typename?: 'InvoiceSalesLine', artikel_nr?: string | null, artikel_stuks?: number | null, artikel_bedrag_incl?: number | null, artikel_bedrag_excl?: number | null, description?: string | null } | null> | null, sales_payments?: Array<{ __typename?: 'InvoiceSalesPayment', valutakode?: string | null, betalingsbedrag?: number | null, betalingsbedrag_val?: number | null, betaalwijze?: string | null } | null> | null, order?: { __typename?: 'Order', id: number, status: OrderStatus, billingAddress: { __typename?: 'OrderAddress', postalCode: string, gender: Gender, firstName: string, lastName: string, street: string, houseNumber: string, houseNumberSuffix?: string | null, city: string, country: string }, items?: Array<{ __typename?: 'OrderItem', size?: string | null, quantity: number, article?: { __typename?: 'Article', brand?: string | null, id?: string | null, price?: number | null, salePrice?: number | null, description?: string | null, images?: Array<string | null> | null } | null } | null> | null } | null } | null };

export type DownloadInvoiceMutationVariables = Exact<{
  ref: Scalars['String']['input'];
}>;


export type DownloadInvoiceMutation = { __typename?: 'Mutation', downloadInvoice?: string | null };

export type NewsletterSignupMutationVariables = Exact<{
  input: NewsletterSignupInput;
}>;


export type NewsletterSignupMutation = { __typename?: 'Mutation', newsletterSignup?: boolean | null };

export type PostalCodeLookupQueryVariables = Exact<{
  postalCode: Scalars['String']['input'];
  houseNumber: Scalars['Int']['input'];
}>;


export type PostalCodeLookupQuery = { __typename?: 'Query', postalCodeLookup?: { __typename?: 'PostalCodeLookupPayload', id?: string | null, postcode?: string | null, number?: number | null, street?: string | null, city?: string | null } | null };

export type SubmitPreOwnedWatchMutationVariables = Exact<{
  input: PreOwnedWatchInput;
}>;


export type SubmitPreOwnedWatchMutation = { __typename?: 'Mutation', submitPreOwnedWatch?: boolean | null };

export type UploadFileGetIdMutationVariables = Exact<{
  upload: Scalars['Upload']['input'];
}>;


export type UploadFileGetIdMutation = { __typename?: 'Mutation', uploadFileGetId?: string | null };

export type ReturnOrderRequestMutationVariables = Exact<{
  input: ReturnOrderRequestInput;
}>;


export type ReturnOrderRequestMutation = { __typename?: 'Mutation', returnOrderRequest?: boolean | null };

export type OrderForReturnQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  email: Scalars['String']['input'];
}>;


export type OrderForReturnQuery = { __typename?: 'Query', orderForReturn?: { __typename?: 'Order', id: number, status: OrderStatus, contactEmail: string, contactPhone: string, items?: Array<{ __typename?: 'OrderItem', id: string, quantity: number, unitPrice: number, totalPrice?: number | null, description: string, articleId: string, size?: string | null, article?: { __typename?: 'Article', id?: string | null, brand?: string | null, description?: string | null, articleType?: string | null, images?: Array<string | null> | null, price?: number | null, salePrice?: number | null } | null } | null> | null, shippingAddress: { __typename?: 'OrderAddress', firstName: string, lastName: string } } | null };

export type SubmitContactFormMutationVariables = Exact<{
  input: ContactFormInput;
  newsletterSignup?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SubmitContactFormMutation = { __typename?: 'Mutation', submitContactForm: boolean };

export type SubmitEventFormMutationVariables = Exact<{
  input: EventFormInput;
}>;


export type SubmitEventFormMutation = { __typename?: 'Mutation', submitEventForm?: boolean | null };

export type TourByCodeAndEmailQueryVariables = Exact<{
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type TourByCodeAndEmailQuery = { __typename?: 'Query', tourByCodeAndEmail?: { __typename?: 'TourBooking', name: string, code: string, persons: number, email: string, date: string, time: string } | null };

export type UpdateNewsletterOptinMutationVariables = Exact<{
  input: UpdateNewsletterOptinInput;
}>;


export type UpdateNewsletterOptinMutation = { __typename?: 'Mutation', updateNewsletterOptin?: boolean | null };

export type PublicWishlistQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PublicWishlistQuery = { __typename?: 'Query', publicWishlist?: { __typename?: 'UserWishlist', id: string, status: UserWishlistStatus, articleIds: Array<string> } | null };

export type UpdateUserWishlistMutationVariables = Exact<{
  id: Scalars['String']['input'];
  articleIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  status: UserWishlistStatus;
}>;


export type UpdateUserWishlistMutation = { __typename?: 'Mutation', updateUserWishlist?: { __typename?: 'UserWishlist', id: string, status: UserWishlistStatus, articleIds: Array<string> } | null };

export type WishlistQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type WishlistQuery = { __typename?: 'Query', wishlist?: { __typename?: 'UserWishlist', id: string, status: UserWishlistStatus, articleIds: Array<string> } | null };

export type WishlistsQueryVariables = Exact<{ [key: string]: never; }>;


export type WishlistsQuery = { __typename?: 'Query', wishlists?: Array<{ __typename?: 'UserWishlist', id: string, status: UserWishlistStatus, articleIds: Array<string> }> | null };

export const UserPreferenceFieldsFragmentDoc = gql`
    fragment UserPreferenceFields on User {
  id
  dafUser {
    optins {
      optin
      value
    }
  }
}
    `;
export const DafUserPreferenceFieldsFragmentDoc = gql`
    fragment DafUserPreferenceFields on DAFPreferenceTypeItem {
  preference_type_id
  preference_type_description
  preferences {
    preference_no
    profile
    isSelected
    description
  }
}
    `;
export const DafUserPrivilegeFieldsFragmentDoc = gql`
    fragment DafUserPrivilegeFields on DAFPrivilegeTypeItem {
  artikel_nr
  beschikbaar
  description
  max_aantal
  privilege_nr
  privilege_action
  preference_type_description
}
    `;
export const ServiceFieldsFragmentDoc = gql`
    fragment ServiceFields on DAFService {
  service_nr
  merk
  model_nr
  serial_nr
  date
  maxprice
  ttservice_tracking {
    status_nr
    status_name
    service_nr
    tijd
    date
  }
}
    `;
export const AddressFieldsFragmentDoc = gql`
    fragment AddressFields on Address {
  id
  createdAt
  updatedAt
  gender
  firstName
  lastName
  companyName
  country
  state
  postalCode
  street
  city
  houseNumber
  houseNumberSuffix
  isBillingAddress
  isShippingAddress
}
    `;
export const ArticleFragmentFragmentDoc = gql`
    fragment ArticleFragment on Article {
  id
  webID
  price
  salePrice
  images
  brand
  modelName
  description
  urlSlug
}
    `;
export const ArticleListFragmentFragmentDoc = gql`
    fragment ArticleListFragment on Article {
  __typename
  id
  webID
  price
  salePrice
  images
  brand
  modelName
  description
  urlSlug
  articleType
  articleTypeID
  isPriceVisible
  collection
  collectionNumber
  articleAttributes {
    attributeName
    attributeID
    attributeValueID
    attributeValue
    attributeValue
    attributeOriginalName
  }
}
    `;
export const ArticlesByIdFragmentFragmentDoc = gql`
    fragment ArticlesByIdFragment on Article {
  id
  price
  salePrice
  images
  brand
  modelName
  description
  urlSlug
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  email
  cart {
    id
  }
  dafUserId
  dafUser {
    klant_nr
    my_loyalty
    voornaam
    Klantnaam
    geslacht
    mobiel
    geboortedatum
    trouwdatum
    loyalty_progress
    ringmaat_links
    ringmaat_rechts
    polsmaat
    pnaam
    pvoorletters
    pvoornaam
    pgeslacht
    pgeboortedatum
  }
}
    `;
export const CartFragmentFragmentDoc = gql`
    fragment CartFragment on Cart {
  id
  totalTax
  totalPriceExTax
  totalPriceIncTax
  totalItems
  shippingCosts
  vouchers {
    id
    type
    code
    value
  }
  items {
    id
    quantity
    unitPrice
    totalPrice
    description
    message
    articleId
    size
    article {
      id
      brand
      description
      articleType
      images
      price
      salePrice
      articleAttributes {
        attributeID
        attributeValue
      }
    }
  }
  privilegeItems {
    id
    privilegeId
    imageSource
    value
    articleId
    description
  }
}
    `;
export const OrderFieldsFragmentDoc = gql`
    fragment OrderFields on Order {
  id
  status
  contactEmail
  contactPhone
  totalPriceIncTax
  totalPriceExTax
  totalTax
  shippingCosts
  createdAt
  vouchers {
    id
    type
    code
    value
  }
  items {
    id
    quantity
    unitPrice
    totalPrice
    description
    message
    articleId
    size
    article {
      id
      brand
      description
      articleType
      images
      price
      salePrice
      articleAttributes {
        attributeID
        attributeValue
      }
    }
  }
  privilegeItems {
    id
    privilegeId
    imageSource
    value
    articleId
    description
  }
  shippingAddress {
    firstName
    lastName
  }
}
    `;
export const FileFieldsFragmentDoc = gql`
    fragment FileFields on File {
  id
  type
  name
  createdAt
}
    `;
export const GiftcardFieldsFragmentDoc = gql`
    fragment GiftcardFields on Giftcard {
  id
  code
  originalValue
}
    `;
export const InvoiceFieldsFragmentDoc = gql`
    fragment InvoiceFields on Invoice {
  id
  idRef
  bonnr
  date
  discount
  shipping_cost
  sales_lines {
    artikel_nr
    artikel_stuks
    artikel_bedrag_incl
    artikel_bedrag_excl
    description
  }
  sales_payments {
    valutakode
    betalingsbedrag
    betalingsbedrag_val
    betaalwijze
  }
  order {
    id
    status
    billingAddress {
      postalCode
      gender
      firstName
      lastName
      street
      houseNumber
      houseNumberSuffix
      city
      country
    }
    items {
      size
      quantity
      article {
        brand
        id
        price
        salePrice
        description
        images
      }
    }
  }
  klant_naam
  email
  adres
  postalcode
  city
  country
}
    `;
export const CreateAccountDocument = gql`
    mutation CreateAccount($input: CreateAccountInput!, $url: String!, $wishlist: [String!], $cartReference: String) {
  createAccount(
    input: $input
    url: $url
    wishlist: $wishlist
    cartReference: $cartReference
  )
}
    `;

export function useCreateAccountMutation() {
  return Urql.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument);
};
export const CheckAccountExistenceDocument = gql`
    query CheckAccountExistence($email: String!) {
  checkAccountExistence(email: $email)
}
    `;

export function useCheckAccountExistenceQuery(options: Omit<Urql.UseQueryArgs<CheckAccountExistenceQueryVariables>, 'query'>) {
  return Urql.useQuery<CheckAccountExistenceQuery, CheckAccountExistenceQueryVariables>({ query: CheckAccountExistenceDocument, ...options });
};
export const ProfileDocument = gql`
    query Profile {
  me {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

export function useProfileQuery(options?: Omit<Urql.UseQueryArgs<ProfileQueryVariables>, 'query'>) {
  return Urql.useQuery<ProfileQuery, ProfileQueryVariables>({ query: ProfileDocument, ...options });
};
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

export function useUpdateProfileMutation() {
  return Urql.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument);
};
export const UpdatePartnerInfoDocument = gql`
    mutation UpdatePartnerInfo($input: UpdatePartnerInfoInput!) {
  updatePartnerInfo(input: $input) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

export function useUpdatePartnerInfoMutation() {
  return Urql.useMutation<UpdatePartnerInfoMutation, UpdatePartnerInfoMutationVariables>(UpdatePartnerInfoDocument);
};
export const ValidateCurrentPasswordDocument = gql`
    query ValidateCurrentPassword($password: String!) {
  validateCurrentPassword(password: $password)
}
    `;

export function useValidateCurrentPasswordQuery(options: Omit<Urql.UseQueryArgs<ValidateCurrentPasswordQueryVariables>, 'query'>) {
  return Urql.useQuery<ValidateCurrentPasswordQuery, ValidateCurrentPasswordQueryVariables>({ query: ValidateCurrentPasswordDocument, ...options });
};
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($currentPassword: String!, $newPassword: String!) {
  updatePassword(currentPassword: $currentPassword, newPassword: $newPassword)
}
    `;

export function useUpdatePasswordMutation() {
  return Urql.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument);
};
export const NewsletterPreferencesDocument = gql`
    query NewsletterPreferences {
  me {
    ...UserPreferenceFields
  }
}
    ${UserPreferenceFieldsFragmentDoc}`;

export function useNewsletterPreferencesQuery(options?: Omit<Urql.UseQueryArgs<NewsletterPreferencesQueryVariables>, 'query'>) {
  return Urql.useQuery<NewsletterPreferencesQuery, NewsletterPreferencesQueryVariables>({ query: NewsletterPreferencesDocument, ...options });
};
export const UpdateNewsletterPreferencesDocument = gql`
    mutation UpdateNewsletterPreferences($optins: [DAFOptins!]!) {
  updateNewsletterPreferences(optins: $optins) {
    ...UserPreferenceFields
  }
}
    ${UserPreferenceFieldsFragmentDoc}`;

export function useUpdateNewsletterPreferencesMutation() {
  return Urql.useMutation<UpdateNewsletterPreferencesMutation, UpdateNewsletterPreferencesMutationVariables>(UpdateNewsletterPreferencesDocument);
};
export const PreferencesDocument = gql`
    query Preferences {
  preferences {
    ...DafUserPreferenceFields
  }
}
    ${DafUserPreferenceFieldsFragmentDoc}`;

export function usePreferencesQuery(options?: Omit<Urql.UseQueryArgs<PreferencesQueryVariables>, 'query'>) {
  return Urql.useQuery<PreferencesQuery, PreferencesQueryVariables>({ query: PreferencesDocument, ...options });
};
export const UpdatePreferencesDocument = gql`
    mutation UpdatePreferences($input: [UpdatePreferencesInput!]!) {
  updatePreferences(input: $input) {
    ...DafUserPreferenceFields
  }
}
    ${DafUserPreferenceFieldsFragmentDoc}`;

export function useUpdatePreferencesMutation() {
  return Urql.useMutation<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>(UpdatePreferencesDocument);
};
export const UpdateBrandPreferencesDocument = gql`
    mutation UpdateBrandPreferences($input: [UpdatePreferencesInput!]!) {
  updatePreferences(input: $input) {
    ...DafUserPreferenceFields
  }
}
    ${DafUserPreferenceFieldsFragmentDoc}`;

export function useUpdateBrandPreferencesMutation() {
  return Urql.useMutation<UpdateBrandPreferencesMutation, UpdateBrandPreferencesMutationVariables>(UpdateBrandPreferencesDocument);
};
export const PrivilegesDocument = gql`
    query Privileges {
  privileges {
    ...DafUserPrivilegeFields
  }
}
    ${DafUserPrivilegeFieldsFragmentDoc}`;

export function usePrivilegesQuery(options?: Omit<Urql.UseQueryArgs<PrivilegesQueryVariables>, 'query'>) {
  return Urql.useQuery<PrivilegesQuery, PrivilegesQueryVariables>({ query: PrivilegesDocument, ...options });
};
export const LoyaltyStatusDocument = gql`
    query LoyaltyStatus {
  loyaltyStatus {
    loyalty_nr
    loyalty_description
    loyalty_progress
  }
}
    `;

export function useLoyaltyStatusQuery(options?: Omit<Urql.UseQueryArgs<LoyaltyStatusQueryVariables>, 'query'>) {
  return Urql.useQuery<LoyaltyStatusQuery, LoyaltyStatusQueryVariables>({ query: LoyaltyStatusDocument, ...options });
};
export const GetServicesDocument = gql`
    query GetServices {
  services {
    total
    items {
      ...ServiceFields
    }
  }
}
    ${ServiceFieldsFragmentDoc}`;

export function useGetServicesQuery(options?: Omit<Urql.UseQueryArgs<GetServicesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetServicesQuery, GetServicesQueryVariables>({ query: GetServicesDocument, ...options });
};
export const GetServiceDocument = gql`
    query GetService($id: Int!) {
  service(id: $id) {
    ...ServiceFields
  }
}
    ${ServiceFieldsFragmentDoc}`;

export function useGetServiceQuery(options: Omit<Urql.UseQueryArgs<GetServiceQueryVariables>, 'query'>) {
  return Urql.useQuery<GetServiceQuery, GetServiceQueryVariables>({ query: GetServiceDocument, ...options });
};
export const AddressesDocument = gql`
    query Addresses {
  addresses {
    ...AddressFields
  }
}
    ${AddressFieldsFragmentDoc}`;

export function useAddressesQuery(options?: Omit<Urql.UseQueryArgs<AddressesQueryVariables>, 'query'>) {
  return Urql.useQuery<AddressesQuery, AddressesQueryVariables>({ query: AddressesDocument, ...options });
};
export const DeleteAddressDocument = gql`
    mutation DeleteAddress($id: String!) {
  deleteAddress(id: $id)
}
    `;

export function useDeleteAddressMutation() {
  return Urql.useMutation<DeleteAddressMutation, DeleteAddressMutationVariables>(DeleteAddressDocument);
};
export const UpdateAddressDocument = gql`
    mutation UpdateAddress($id: String!, $input: AddressInput!) {
  updateAddress(id: $id, input: $input) {
    ...AddressFields
  }
}
    ${AddressFieldsFragmentDoc}`;

export function useUpdateAddressMutation() {
  return Urql.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument);
};
export const AddAddressDocument = gql`
    mutation AddAddress($input: AddressInput!) {
  addAddress(input: $input) {
    ...AddressFields
  }
}
    ${AddressFieldsFragmentDoc}`;

export function useAddAddressMutation() {
  return Urql.useMutation<AddAddressMutation, AddAddressMutationVariables>(AddAddressDocument);
};
export const AdminEventFormDocument = gql`
    query AdminEventForm($id: String!) {
  form(id: $id) {
    name
    note
    fields
    selectableDates
    selectableTimes
    customNewsletterText
    customNumberOfPersons
    redirectAfterSubmission
  }
}
    `;

export function useAdminEventFormQuery(options: Omit<Urql.UseQueryArgs<AdminEventFormQueryVariables>, 'query'>) {
  return Urql.useQuery<AdminEventFormQuery, AdminEventFormQueryVariables>({ query: AdminEventFormDocument, ...options });
};
export const ArticleDocument = gql`
    query Article($id: String!) {
  article(id: $id) {
    ...ArticleFragment
    supplierNr
    description
    collection
    modelName
    eancode
    articleType
    articleTypeID
    inStock
    isWebshopSale
    ignoreStockCheck
    isPriceVisible
    isWebShopOnly
    isOutOfCollection
    collectionNumber
    articleAttributes {
      attributeName
      attributeID
      attributeValueID
      attributeValue
      attributeValue
      attributeOriginalName
    }
  }
}
    ${ArticleFragmentFragmentDoc}`;

export function useArticleQuery(options: Omit<Urql.UseQueryArgs<ArticleQueryVariables>, 'query'>) {
  return Urql.useQuery<ArticleQuery, ArticleQueryVariables>({ query: ArticleDocument, ...options });
};
export const ArticlesDocument = gql`
    query Articles($pagination: ArticlesPaginationInput, $filters: ArticlesFilterInput) {
  articles(pagination: $pagination, filters: $filters) {
    total
    items {
      ...ArticleListFragment
    }
  }
}
    ${ArticleListFragmentFragmentDoc}`;

export function useArticlesQuery(options?: Omit<Urql.UseQueryArgs<ArticlesQueryVariables>, 'query'>) {
  return Urql.useQuery<ArticlesQuery, ArticlesQueryVariables>({ query: ArticlesDocument, ...options });
};
export const FiltersDocument = gql`
    query Filters($filters: ArticlesFilterInput) {
  filters(filters: $filters) {
    articleTypeFilters {
      articleTypeID
      articleType
    }
    brandFilters {
      merk_nr
      brand
    }
    brandCollectionFilters {
      collection
      collectionNumber
    }
    articleAttributeFilters {
      attributeID
      attributeName
      articleFilterAttributeValues {
        attributeValueID
        attributeValue
      }
    }
  }
}
    `;

export function useFiltersQuery(options?: Omit<Urql.UseQueryArgs<FiltersQueryVariables>, 'query'>) {
  return Urql.useQuery<FiltersQuery, FiltersQueryVariables>({ query: FiltersDocument, ...options });
};
export const ArticlesByIdDocument = gql`
    query ArticlesByID($ids: [String!]!) {
  articlesById(ids: $ids) {
    ...ArticlesByIdFragment
    articleType
    inStock
    isWebshopSale
    ignoreStockCheck
    isPriceVisible
    isWebShopOnly
    isOutOfCollection
    articleAttributes {
      attributeName
      attributeValue
      attributeOriginalName
    }
  }
}
    ${ArticlesByIdFragmentFragmentDoc}`;

export function useArticlesByIdQuery(options: Omit<Urql.UseQueryArgs<ArticlesByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<ArticlesByIdQuery, ArticlesByIdQueryVariables>({ query: ArticlesByIdDocument, ...options });
};
export const MeDocument = gql`
    query Me {
  me {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

export function useMeQuery(options?: Omit<Urql.UseQueryArgs<MeQueryVariables>, 'query'>) {
  return Urql.useQuery<MeQuery, MeQueryVariables>({ query: MeDocument, ...options });
};
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password, loginTo: account) {
    authToken
    refreshToken
    user {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
};
export const RenewTokenDocument = gql`
    mutation RenewToken($authToken: String!, $refreshToken: String!) {
  renewToken(authToken: $authToken, refreshToken: $refreshToken) {
    authToken
    refreshToken
  }
}
    `;

export function useRenewTokenMutation() {
  return Urql.useMutation<RenewTokenMutation, RenewTokenMutationVariables>(RenewTokenDocument);
};
export const RequestResetPasswordDocument = gql`
    mutation RequestResetPassword($email: String!, $url: String!) {
  requestResetPassword(email: $email, url: $url)
}
    `;

export function useRequestResetPasswordMutation() {
  return Urql.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument);
};
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $newPassword: String!) {
  resetPassword(token: $token, newPassword: $newPassword) {
    authToken
    refreshToken
    user {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;

export function useResetPasswordMutation() {
  return Urql.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument);
};
export const CartTotalItemsDocument = gql`
    query CartTotalItems($reference: String!) {
  cart(reference: $reference) {
    id
    totalItems
  }
}
    `;

export function useCartTotalItemsQuery(options: Omit<Urql.UseQueryArgs<CartTotalItemsQueryVariables>, 'query'>) {
  return Urql.useQuery<CartTotalItemsQuery, CartTotalItemsQueryVariables>({ query: CartTotalItemsDocument, ...options });
};
export const CartDocument = gql`
    query Cart($reference: String) {
  cart(reference: $reference) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;

export function useCartQuery(options?: Omit<Urql.UseQueryArgs<CartQueryVariables>, 'query'>) {
  return Urql.useQuery<CartQuery, CartQueryVariables>({ query: CartDocument, ...options });
};
export const DeleteCartDocument = gql`
    mutation DeleteCart($reference: String!) {
  deleteCart(reference: $reference)
}
    `;

export function useDeleteCartMutation() {
  return Urql.useMutation<DeleteCartMutation, DeleteCartMutationVariables>(DeleteCartDocument);
};
export const AddArticleToCartDocument = gql`
    mutation AddArticleToCart($reference: String!, $articleId: String!, $size: String, $quantity: Int, $message: String) {
  addArticleToCart(
    reference: $reference
    articleId: $articleId
    size: $size
    quantity: $quantity
    message: $message
  ) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;

export function useAddArticleToCartMutation() {
  return Urql.useMutation<AddArticleToCartMutation, AddArticleToCartMutationVariables>(AddArticleToCartDocument);
};
export const AddGiftcardToCartDocument = gql`
    mutation AddGiftcardToCart($reference: String!, $amount: Int!, $quantity: Int) {
  addGiftcardToCart(reference: $reference, amount: $amount, quantity: $quantity) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;

export function useAddGiftcardToCartMutation() {
  return Urql.useMutation<AddGiftcardToCartMutation, AddGiftcardToCartMutationVariables>(AddGiftcardToCartDocument);
};
export const RemoveItemFromCartDocument = gql`
    mutation RemoveItemFromCart($reference: String!, $cartItemId: String!) {
  removeItemFromCart(reference: $reference, cartItemId: $cartItemId) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;

export function useRemoveItemFromCartMutation() {
  return Urql.useMutation<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>(RemoveItemFromCartDocument);
};
export const UpdateCartItemQuantityDocument = gql`
    mutation UpdateCartItemQuantity($reference: String!, $cartItemId: String!, $quantity: Int!) {
  updateCartItemQuantity(
    reference: $reference
    cartItemId: $cartItemId
    quantity: $quantity
  ) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;

export function useUpdateCartItemQuantityMutation() {
  return Urql.useMutation<UpdateCartItemQuantityMutation, UpdateCartItemQuantityMutationVariables>(UpdateCartItemQuantityDocument);
};
export const UpdateCartItemSizeDocument = gql`
    mutation UpdateCartItemSize($reference: String!, $cartItemId: String!, $size: String) {
  updateCartItemSize(reference: $reference, cartItemId: $cartItemId, size: $size) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;

export function useUpdateCartItemSizeMutation() {
  return Urql.useMutation<UpdateCartItemSizeMutation, UpdateCartItemSizeMutationVariables>(UpdateCartItemSizeDocument);
};
export const UpdateShippingCostsDocument = gql`
    mutation UpdateShippingCosts($reference: String!, $countryCode: String!) {
  updateShippingCosts(reference: $reference, countryCode: $countryCode) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;

export function useUpdateShippingCostsMutation() {
  return Urql.useMutation<UpdateShippingCostsMutation, UpdateShippingCostsMutationVariables>(UpdateShippingCostsDocument);
};
export const AddVoucherDocument = gql`
    mutation AddVoucher($reference: String!, $code: String!) {
  addVoucher(reference: $reference, code: $code) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;

export function useAddVoucherMutation() {
  return Urql.useMutation<AddVoucherMutation, AddVoucherMutationVariables>(AddVoucherDocument);
};
export const RemoveVoucherDocument = gql`
    mutation RemoveVoucher($reference: String!, $code: String!) {
  removeVoucher(reference: $reference, code: $code) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;

export function useRemoveVoucherMutation() {
  return Urql.useMutation<RemoveVoucherMutation, RemoveVoucherMutationVariables>(RemoveVoucherDocument);
};
export const ClearCartDocument = gql`
    mutation ClearCart($reference: String!) {
  clearCart(reference: $reference) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;

export function useClearCartMutation() {
  return Urql.useMutation<ClearCartMutation, ClearCartMutationVariables>(ClearCartDocument);
};
export const AddPrivilegeToCartDocument = gql`
    mutation AddPrivilegeToCart($input: AddPrivilegeToCartInput!) {
  addPrivilegeToCart(input: $input) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;

export function useAddPrivilegeToCartMutation() {
  return Urql.useMutation<AddPrivilegeToCartMutation, AddPrivilegeToCartMutationVariables>(AddPrivilegeToCartDocument);
};
export const RemovePrivilegeItemFromCartDocument = gql`
    mutation RemovePrivilegeItemFromCart($input: RemovePrivilegeItemFromCartInput!) {
  removePrivilegeItemFromCart(input: $input) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;

export function useRemovePrivilegeItemFromCartMutation() {
  return Urql.useMutation<RemovePrivilegeItemFromCartMutation, RemovePrivilegeItemFromCartMutationVariables>(RemovePrivilegeItemFromCartDocument);
};
export const CdcLoginDocument = gql`
    mutation CdcLogin($input: CdcLoginInput!) {
  cdcLogin(input: $input)
}
    `;

export function useCdcLoginMutation() {
  return Urql.useMutation<CdcLoginMutation, CdcLoginMutationVariables>(CdcLoginDocument);
};
export const CdcCompleteSocialLoginDocument = gql`
    mutation CdcCompleteSocialLogin($input: CdcCompleteSocialLoginInput!) {
  cdcCompleteSocialLogin(input: $input)
}
    `;

export function useCdcCompleteSocialLoginMutation() {
  return Urql.useMutation<CdcCompleteSocialLoginMutation, CdcCompleteSocialLoginMutationVariables>(CdcCompleteSocialLoginDocument);
};
export const CdcRequestResendVerificationCodeDocument = gql`
    mutation CdcRequestResendVerificationCode($input: CdcRequestResendVerificationCodeInput!) {
  cdcRequestResendVerificationCode(input: $input)
}
    `;

export function useCdcRequestResendVerificationCodeMutation() {
  return Urql.useMutation<CdcRequestResendVerificationCodeMutation, CdcRequestResendVerificationCodeMutationVariables>(CdcRequestResendVerificationCodeDocument);
};
export const CheckoutDocument = gql`
    mutation Checkout($reference: String!, $input: CheckoutInput!, $meta: CheckoutMetaInput) {
  checkout(reference: $reference, input: $input, meta: $meta) {
    id
    status
  }
}
    `;

export function useCheckoutMutation() {
  return Urql.useMutation<CheckoutMutation, CheckoutMutationVariables>(CheckoutDocument);
};
export const OrderByIdAndEmailDocument = gql`
    query OrderByIdAndEmail($id: Int!, $email: String!) {
  orderByIdAndEmail(id: $id, email: $email) {
    ...OrderFields
    deliveryType
    shippingAddress {
      id
      gender
      companyName
      firstName
      lastName
      street
      houseNumber
      houseNumberSuffix
      postalCode
      city
      country
    }
    billingAddress {
      id
      gender
      companyName
      firstName
      lastName
      street
      houseNumber
      houseNumberSuffix
      postalCode
      city
      country
    }
    transactions {
      type
      paymentMethod
      reference
      amount
    }
  }
}
    ${OrderFieldsFragmentDoc}`;

export function useOrderByIdAndEmailQuery(options: Omit<Urql.UseQueryArgs<OrderByIdAndEmailQueryVariables>, 'query'>) {
  return Urql.useQuery<OrderByIdAndEmailQuery, OrderByIdAndEmailQueryVariables>({ query: OrderByIdAndEmailDocument, ...options });
};
export const PaymentMethodsDocument = gql`
    query PaymentMethods($amount: Int!) {
  paymentMethods(amount: $amount)
}
    `;

export function usePaymentMethodsQuery(options: Omit<Urql.UseQueryArgs<PaymentMethodsQueryVariables>, 'query'>) {
  return Urql.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>({ query: PaymentMethodsDocument, ...options });
};
export const PaymentDocument = gql`
    mutation Payment($input: PaymentInput!) {
  payment(input: $input)
}
    `;

export function usePaymentMutation() {
  return Urql.useMutation<PaymentMutation, PaymentMutationVariables>(PaymentDocument);
};
export const PaymentDetailsDocument = gql`
    mutation PaymentDetails($input: PaymentDetailsInput!) {
  paymentDetails(input: $input)
}
    `;

export function usePaymentDetailsMutation() {
  return Urql.useMutation<PaymentDetailsMutation, PaymentDetailsMutationVariables>(PaymentDetailsDocument);
};
export const VerifyAdyenPaymentDocument = gql`
    query VerifyAdyenPayment($payload: String!) {
  verifyAdyenPayment(payload: $payload)
}
    `;

export function useVerifyAdyenPaymentQuery(options: Omit<Urql.UseQueryArgs<VerifyAdyenPaymentQueryVariables>, 'query'>) {
  return Urql.useQuery<VerifyAdyenPaymentQuery, VerifyAdyenPaymentQueryVariables>({ query: VerifyAdyenPaymentDocument, ...options });
};
export const ValidateGiftCardDocument = gql`
    mutation ValidateGiftCard($reference: String!, $input: ValidateGiftCardInput!) {
  validateGiftCard(reference: $reference, input: $input)
}
    `;

export function useValidateGiftCardMutation() {
  return Urql.useMutation<ValidateGiftCardMutation, ValidateGiftCardMutationVariables>(ValidateGiftCardDocument);
};
export const ValidateGiftCardsDocument = gql`
    mutation ValidateGiftCards($reference: String!, $input: [ValidateGiftCardsInput!]!) {
  validateGiftCards(reference: $reference, input: $input) {
    appliedGiftcards {
      giftcardNumber
      securityCode
      appliedAmount
    }
    totalAmount
  }
}
    `;

export function useValidateGiftCardsMutation() {
  return Urql.useMutation<ValidateGiftCardsMutation, ValidateGiftCardsMutationVariables>(ValidateGiftCardsDocument);
};
export const CheckoutAddressesDocument = gql`
    query CheckoutAddresses {
  me {
    id
    email
    dafUser {
      mobiel
    }
  }
  addresses {
    ...AddressFields
  }
}
    ${AddressFieldsFragmentDoc}`;

export function useCheckoutAddressesQuery(options?: Omit<Urql.UseQueryArgs<CheckoutAddressesQueryVariables>, 'query'>) {
  return Urql.useQuery<CheckoutAddressesQuery, CheckoutAddressesQueryVariables>({ query: CheckoutAddressesDocument, ...options });
};
export const AdyenCreateCheckoutSessionDocument = gql`
    mutation AdyenCreateCheckoutSession($input: AdyenCreateCheckoutSessionInput!) {
  adyenCreateCheckoutSession(input: $input) {
    sessionId
    sessionData
  }
}
    `;

export function useAdyenCreateCheckoutSessionMutation() {
  return Urql.useMutation<AdyenCreateCheckoutSessionMutation, AdyenCreateCheckoutSessionMutationVariables>(AdyenCreateCheckoutSessionDocument);
};
export const OrderVerifyFullyPaidDocument = gql`
    mutation OrderVerifyFullyPaid($orderId: Int!) {
  orderVerifyFullyPaid(id: $orderId) {
    fullyPaid
  }
}
    `;

export function useOrderVerifyFullyPaidMutation() {
  return Urql.useMutation<OrderVerifyFullyPaidMutation, OrderVerifyFullyPaidMutationVariables>(OrderVerifyFullyPaidDocument);
};
export const OrderAddVoucherDocument = gql`
    mutation OrderAddVoucher($orderId: Int!, $voucherCode: String!) {
  orderAddVoucher(id: $orderId, code: $voucherCode)
}
    `;

export function useOrderAddVoucherMutation() {
  return Urql.useMutation<OrderAddVoucherMutation, OrderAddVoucherMutationVariables>(OrderAddVoucherDocument);
};
export const OrderAddGiftcardDocument = gql`
    mutation OrderAddGiftcard($orderId: Int!, $input: AddGiftcardToOrderGiftcardInput!) {
  orderAddGiftcard(id: $orderId, input: $input)
}
    `;

export function useOrderAddGiftcardMutation() {
  return Urql.useMutation<OrderAddGiftcardMutation, OrderAddGiftcardMutationVariables>(OrderAddGiftcardDocument);
};
export const OrderRemoveGiftcardDocument = gql`
    mutation OrderRemoveGiftcard($orderId: Int!, $giftcardNumber: String!) {
  orderRemoveGiftcard(id: $orderId, giftcardNumber: $giftcardNumber)
}
    `;

export function useOrderRemoveGiftcardMutation() {
  return Urql.useMutation<OrderRemoveGiftcardMutation, OrderRemoveGiftcardMutationVariables>(OrderRemoveGiftcardDocument);
};
export const OrderRemoveVoucherDocument = gql`
    mutation OrderRemoveVoucher($orderId: Int!, $code: String!) {
  orderRemoveVoucher(id: $orderId, code: $code)
}
    `;

export function useOrderRemoveVoucherMutation() {
  return Urql.useMutation<OrderRemoveVoucherMutation, OrderRemoveVoucherMutationVariables>(OrderRemoveVoucherDocument);
};
export const CreateFormSubmissionDocument = gql`
    mutation CreateFormSubmission($id: String!, $input: CreateFormSubmissionInput!) {
  createFormSubmission(formId: $id, input: $input) {
    id
  }
}
    `;

export function useCreateFormSubmissionMutation() {
  return Urql.useMutation<CreateFormSubmissionMutation, CreateFormSubmissionMutationVariables>(CreateFormSubmissionDocument);
};
export const UserFilesDocument = gql`
    query UserFiles($orderBy: QueryFilesOrderByInput, $take: Int, $skip: Int) {
  userFiles(orderBy: $orderBy, take: $take, skip: $skip) {
    items {
      ...FileFields
    }
    total
  }
}
    ${FileFieldsFragmentDoc}`;

export function useUserFilesQuery(options?: Omit<Urql.UseQueryArgs<UserFilesQueryVariables>, 'query'>) {
  return Urql.useQuery<UserFilesQuery, UserFilesQueryVariables>({ query: UserFilesDocument, ...options });
};
export const DeleteUserFileDocument = gql`
    mutation DeleteUserFile($id: String!) {
  deleteUserFile(id: $id)
}
    `;

export function useDeleteUserFileMutation() {
  return Urql.useMutation<DeleteUserFileMutation, DeleteUserFileMutationVariables>(DeleteUserFileDocument);
};
export const DownloadUserFileDocument = gql`
    mutation DownloadUserFile($id: String!) {
  downloadUserFile(id: $id)
}
    `;

export function useDownloadUserFileMutation() {
  return Urql.useMutation<DownloadUserFileMutation, DownloadUserFileMutationVariables>(DownloadUserFileDocument);
};
export const UploadUserFileDocument = gql`
    mutation UploadUserFile($input: UploadFileInput!, $upload: Upload!) {
  uploadUserFile(input: $input, upload: $upload) {
    ...FileFields
  }
}
    ${FileFieldsFragmentDoc}`;

export function useUploadUserFileMutation() {
  return Urql.useMutation<UploadUserFileMutation, UploadUserFileMutationVariables>(UploadUserFileDocument);
};
export const FormSubmissionDocument = gql`
    query FormSubmission($id: String!) {
  userFormSubmission(formId: $id) {
    firstName
    lastName
    email
    values
  }
}
    `;

export function useFormSubmissionQuery(options: Omit<Urql.UseQueryArgs<FormSubmissionQueryVariables>, 'query'>) {
  return Urql.useQuery<FormSubmissionQuery, FormSubmissionQueryVariables>({ query: FormSubmissionDocument, ...options });
};
export const GiftcardsDocument = gql`
    query Giftcards {
  giftcards {
    ...GiftcardFields
  }
}
    ${GiftcardFieldsFragmentDoc}`;

export function useGiftcardsQuery(options?: Omit<Urql.UseQueryArgs<GiftcardsQueryVariables>, 'query'>) {
  return Urql.useQuery<GiftcardsQuery, GiftcardsQueryVariables>({ query: GiftcardsDocument, ...options });
};
export const DeleteGiftcardDocument = gql`
    mutation DeleteGiftcard($id: String!) {
  deleteGiftcard(id: $id)
}
    `;

export function useDeleteGiftcardMutation() {
  return Urql.useMutation<DeleteGiftcardMutation, DeleteGiftcardMutationVariables>(DeleteGiftcardDocument);
};
export const AddGiftcardDocument = gql`
    mutation AddGiftcard($input: GiftcardInput!) {
  addGiftcard(input: $input) {
    ...GiftcardFields
  }
}
    ${GiftcardFieldsFragmentDoc}`;

export function useAddGiftcardMutation() {
  return Urql.useMutation<AddGiftcardMutation, AddGiftcardMutationVariables>(AddGiftcardDocument);
};
export const OriginalValueGiftcardDocument = gql`
    query OriginalValueGiftcard($input: OriginalValueGiftcardInput!) {
  originalValueGiftcard(input: $input)
}
    `;

export function useOriginalValueGiftcardQuery(options: Omit<Urql.UseQueryArgs<OriginalValueGiftcardQueryVariables>, 'query'>) {
  return Urql.useQuery<OriginalValueGiftcardQuery, OriginalValueGiftcardQueryVariables>({ query: OriginalValueGiftcardDocument, ...options });
};
export const CheckGiftcardBalanceDocument = gql`
    mutation CheckGiftcardBalance($input: CheckGiftcardBalanceInput!) {
  checkGiftCardBalance(input: $input)
}
    `;

export function useCheckGiftcardBalanceMutation() {
  return Urql.useMutation<CheckGiftcardBalanceMutation, CheckGiftcardBalanceMutationVariables>(CheckGiftcardBalanceDocument);
};
export const InvoicesDocument = gql`
    query Invoices {
  invoices {
    items {
      ...InvoiceFields
    }
    total
  }
}
    ${InvoiceFieldsFragmentDoc}`;

export function useInvoicesQuery(options?: Omit<Urql.UseQueryArgs<InvoicesQueryVariables>, 'query'>) {
  return Urql.useQuery<InvoicesQuery, InvoicesQueryVariables>({ query: InvoicesDocument, ...options });
};
export const InvoiceDocument = gql`
    query Invoice($id: String!) {
  invoice(id: $id) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;

export function useInvoiceQuery(options: Omit<Urql.UseQueryArgs<InvoiceQueryVariables>, 'query'>) {
  return Urql.useQuery<InvoiceQuery, InvoiceQueryVariables>({ query: InvoiceDocument, ...options });
};
export const DownloadInvoiceDocument = gql`
    mutation DownloadInvoice($ref: String!) {
  downloadInvoice(ref: $ref)
}
    `;

export function useDownloadInvoiceMutation() {
  return Urql.useMutation<DownloadInvoiceMutation, DownloadInvoiceMutationVariables>(DownloadInvoiceDocument);
};
export const NewsletterSignupDocument = gql`
    mutation NewsletterSignup($input: NewsletterSignupInput!) {
  newsletterSignup(input: $input)
}
    `;

export function useNewsletterSignupMutation() {
  return Urql.useMutation<NewsletterSignupMutation, NewsletterSignupMutationVariables>(NewsletterSignupDocument);
};
export const PostalCodeLookupDocument = gql`
    query PostalCodeLookup($postalCode: String!, $houseNumber: Int!) {
  postalCodeLookup(postalCode: $postalCode, houseNumber: $houseNumber) {
    id
    postcode
    number
    street
    city
  }
}
    `;

export function usePostalCodeLookupQuery(options: Omit<Urql.UseQueryArgs<PostalCodeLookupQueryVariables>, 'query'>) {
  return Urql.useQuery<PostalCodeLookupQuery, PostalCodeLookupQueryVariables>({ query: PostalCodeLookupDocument, ...options });
};
export const SubmitPreOwnedWatchDocument = gql`
    mutation SubmitPreOwnedWatch($input: PreOwnedWatchInput!) {
  submitPreOwnedWatch(input: $input)
}
    `;

export function useSubmitPreOwnedWatchMutation() {
  return Urql.useMutation<SubmitPreOwnedWatchMutation, SubmitPreOwnedWatchMutationVariables>(SubmitPreOwnedWatchDocument);
};
export const UploadFileGetIdDocument = gql`
    mutation UploadFileGetId($upload: Upload!) {
  uploadFileGetId(upload: $upload)
}
    `;

export function useUploadFileGetIdMutation() {
  return Urql.useMutation<UploadFileGetIdMutation, UploadFileGetIdMutationVariables>(UploadFileGetIdDocument);
};
export const ReturnOrderRequestDocument = gql`
    mutation ReturnOrderRequest($input: ReturnOrderRequestInput!) {
  returnOrderRequest(input: $input)
}
    `;

export function useReturnOrderRequestMutation() {
  return Urql.useMutation<ReturnOrderRequestMutation, ReturnOrderRequestMutationVariables>(ReturnOrderRequestDocument);
};
export const OrderForReturnDocument = gql`
    query OrderForReturn($id: Int!, $email: String!) {
  orderForReturn(id: $id, email: $email) {
    id
    status
    contactEmail
    contactPhone
    items {
      id
      quantity
      unitPrice
      totalPrice
      description
      articleId
      size
      article {
        id
        brand
        description
        articleType
        images
        price
        salePrice
      }
    }
    shippingAddress {
      firstName
      lastName
    }
  }
}
    `;

export function useOrderForReturnQuery(options: Omit<Urql.UseQueryArgs<OrderForReturnQueryVariables>, 'query'>) {
  return Urql.useQuery<OrderForReturnQuery, OrderForReturnQueryVariables>({ query: OrderForReturnDocument, ...options });
};
export const SubmitContactFormDocument = gql`
    mutation SubmitContactForm($input: ContactFormInput!, $newsletterSignup: Boolean) {
  submitContactForm(input: $input, newsletterSignup: $newsletterSignup)
}
    `;

export function useSubmitContactFormMutation() {
  return Urql.useMutation<SubmitContactFormMutation, SubmitContactFormMutationVariables>(SubmitContactFormDocument);
};
export const SubmitEventFormDocument = gql`
    mutation SubmitEventForm($input: EventFormInput!) {
  submitEventForm(input: $input)
}
    `;

export function useSubmitEventFormMutation() {
  return Urql.useMutation<SubmitEventFormMutation, SubmitEventFormMutationVariables>(SubmitEventFormDocument);
};
export const TourByCodeAndEmailDocument = gql`
    query TourByCodeAndEmail($code: String!, $email: String!) {
  tourByCodeAndEmail(code: $code, email: $email) {
    name
    code
    persons
    email
    date
    time
  }
}
    `;

export function useTourByCodeAndEmailQuery(options: Omit<Urql.UseQueryArgs<TourByCodeAndEmailQueryVariables>, 'query'>) {
  return Urql.useQuery<TourByCodeAndEmailQuery, TourByCodeAndEmailQueryVariables>({ query: TourByCodeAndEmailDocument, ...options });
};
export const UpdateNewsletterOptinDocument = gql`
    mutation UpdateNewsletterOptin($input: UpdateNewsletterOptinInput!) {
  updateNewsletterOptin(input: $input)
}
    `;

export function useUpdateNewsletterOptinMutation() {
  return Urql.useMutation<UpdateNewsletterOptinMutation, UpdateNewsletterOptinMutationVariables>(UpdateNewsletterOptinDocument);
};
export const PublicWishlistDocument = gql`
    query PublicWishlist($id: String!) {
  publicWishlist(id: $id) {
    id
    status
    articleIds
  }
}
    `;

export function usePublicWishlistQuery(options: Omit<Urql.UseQueryArgs<PublicWishlistQueryVariables>, 'query'>) {
  return Urql.useQuery<PublicWishlistQuery, PublicWishlistQueryVariables>({ query: PublicWishlistDocument, ...options });
};
export const UpdateUserWishlistDocument = gql`
    mutation UpdateUserWishlist($id: String!, $articleIds: [String!]!, $status: UserWishlistStatus!) {
  updateUserWishlist(id: $id, articleIds: $articleIds, status: $status) {
    id
    status
    articleIds
  }
}
    `;

export function useUpdateUserWishlistMutation() {
  return Urql.useMutation<UpdateUserWishlistMutation, UpdateUserWishlistMutationVariables>(UpdateUserWishlistDocument);
};
export const WishlistDocument = gql`
    query Wishlist($id: String!) {
  wishlist(id: $id) {
    id
    status
    articleIds
  }
}
    `;

export function useWishlistQuery(options: Omit<Urql.UseQueryArgs<WishlistQueryVariables>, 'query'>) {
  return Urql.useQuery<WishlistQuery, WishlistQueryVariables>({ query: WishlistDocument, ...options });
};
export const WishlistsDocument = gql`
    query Wishlists {
  wishlists {
    id
    status
    articleIds
  }
}
    `;

export function useWishlistsQuery(options?: Omit<Urql.UseQueryArgs<WishlistsQueryVariables>, 'query'>) {
  return Urql.useQuery<WishlistsQuery, WishlistsQueryVariables>({ query: WishlistsDocument, ...options });
};